import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Contact from './Contact'
import '../styles/Management.css'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

const Management = () => {
    return (
        <>
            <Navbar/>
            <div className='text-container'>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <h1 className='management-title'>Vacation Rental Management</h1>
                <h6 className='management-header-text'>Bee Our Guests is a full-service luxury vacation rental management company. Services include:</h6>
                <br/>
                <div class="mangagement-card-group card-group">
                    <div class="mangagement-card card">
                        <div class="card-body">
                            <LocalOfferOutlinedIcon className='icon' color='' size='large' />
                            <h5 class="card-title">Channel Management and Dynamic Pricing</h5>
                            <p class="management-card-text">Listing each property on multiple channels to increase opportunities for bookings. Bee Our Guests manages listings across a variety of platforms such as Airbnb, VRBO as well as direct reservations from our very own booking platform.
                            <br/>
                            <br/>
                            Our team utilizes revenue management technology to optimize daily rates based on a variety of key factors—from type of home, location, amenities, day of week, holidays and seasonality—to maximize income.</p>
                        </div>
                    </div>
                    <div class="mangagement-card card">
                        <div class="card-body">
                            <CottageOutlinedIcon className='icon' color='' size='large' />
                            <h5 class="card-title">Housekeeping</h5>
                            <p class="management-card-text"> We partner with a network of hand-selected, local housekeeping partners who we train regularly to ensure quality control. Our team inspects for potential damages, manages inventory and restocks items (toiletries, paper goods and essential pantry items), provides laundry services to efficiently reset each property after every reservation. We also provide housekeeping after an owner’s stay. Our cleaning process follows a three-part protocol including COVID disinfecting, turnover and quality control inspection.</p>
                        </div>
                    </div>
                    <div class="mangagement-card card">
                        <div class="card-body">
                            <QuestionAnswerOutlinedIcon className='icon' color='' size='large' />
                            <h5 class="card-title">Guest Communications & Support</h5>
                            <p class="management-card-text"> Our Guest Support team manages inquiries to check-in/out details, questions and maintenance issues during a reservation and post-stay follow up to create a long-lasting relationship with each guest.</p>
                        </div>
                    </div>
                </div>
                <br/>
                <div class="mangagement-card-group card-group">
                    <div class="mangagement-card card">
                        <div class="card-body">
                            <BuildOutlinedIcon className='icon' color='' size='large' />
                            <h5 class="card-title">Maintenance & Operations</h5>
                            <p class="management-card-text"> Our Maintenance Team regularly checks on each home to monitor utilities and make small repairs as needed. More comprehensive coverage is offered via our Maintenance Plans. The team can also take on select larger projects when possible, from painting to appliance repair, etc.
                            <br/>
                            <br/>
                            Our team utilizes revenue management technology to optimize daily rates based on a variety of key factors—from type of home, location, amenities, day of week, holidays and seasonality—to maximize income.</p>
                        </div>
                    </div>
                    <div class="mangagement-card card">
                        <div class="card-body">
                            <CampaignOutlinedIcon className='icon' color='' size='large' />
                            <h5 class="card-title">Marketing</h5>
                            <p class="management-card-text"> From listing creation to professional photography and development of messaging, Bee Our Guests manages the entire process of setting your property up for success; launch campaigns and year-round outreach to our proprietary database; additional services such as renovations and interior design consultation provided through our trusted partners as needed.</p>
                        </div>
                    </div>
                    <div class="mangagement-card card">
                        <div class="card-body">
                            <NotificationsNoneOutlinedIcon className='icon' color='' size='large' />
                            <h5 class="card-title">Concierge Services</h5>
                            <p class="management-card-text">We partner with local service providers such as private chefs, massage therapists, yoga instructions and many others to connect guests with unique experiences to elevate their stay. We are committed to driving visitors to local business and tourism dollars to the community. This complimentary service inherently ensures guests satisfaction, leading to five star reviews, referrals and repeat business.</p>
                        </div>
                    </div>
                </div>
                
                <br/>
                <br/>
                <p className='rental-contact-text'>If you are a current homeowner and plan to rent out your property, we would love to connect.  Please contact us below.</p>
            </div>
            <hr/>
            <Contact />
            <Footer />
        </>
    )
}

export default Management