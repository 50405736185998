import React from 'react'
import '../styles/PropertyListings.css'
import BedTwoToneIcon from '@mui/icons-material/BedTwoTone';
import BathtubTwoToneIcon from '@mui/icons-material/BathtubTwoTone';
import HailTwoToneIcon from '@mui/icons-material/HailTwoTone';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import { useNavigate } from 'react-router-dom'

const PropertyListings = ({ property }) => {
    const { id, name, images: { main, additional }, description, guests, price, bedrooms, bathrooms, location, googleMapsLocation, amenities, bookingLink } = property
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/properties/${id}`, { state: { id, name, guests, price, bedrooms, bathrooms, images: { main, additional }, description, location, googleMapsLocation, amenities, bookingLink } })
    }

    return (
        <>
            <div className="card" key={id}>
                <img src={main} className="card-img-top" alt={name} onClick={() => handleClick()}/>
                <div className="card-body">
                    <h5 className="card-title-property">{name}</h5>
                    {/* <p className="card-text">{description}</p> */}
                    <span className='iconNumbers'><LocationOnTwoToneIcon className='cardIcon' color='' size='large'></LocationOnTwoToneIcon>{location}</span>
                    <hr/>
                    <div className='icon-container'>
                        <span className='iconNumbers'><HailTwoToneIcon className='cardIcon' color='' size='large'/>{guests}</span>
                        <span className='iconNumbers'><BedTwoToneIcon className='cardIcon' color='' size='large'/>{bedrooms}</span>
                        <span className='iconNumbers'><BathtubTwoToneIcon className='cardIcon' color='' size='large' />{bathrooms}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PropertyListings