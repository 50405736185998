import React from 'react'
import '../styles/Footer.css'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
    return (
        <footer className='bg-dark text-white pt-5 pb-4'>
            <div className='container text-center text-md-left bg-dark'>
                <div className='row text-center text-md-left'>
                    <div className='col-md-3 col-lg-3 col-xl-3 mx-auto mt-3'>
                        <h5 className='text-uppercase mb-4 font-weight-bold text-warning'>Bee Our Guests</h5>
                        <p>Vacation Home Rentals</p>
                        {/* <p>Management</p>
                        <p className='airbnb-text text-white'>AirBnB Super Host</p>
                        <p>Vrbo Premier Host</p> */}
                    </div>

                    <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mt-3'>
                        <h5 className='text-uppercase mb-4 font-weight-bold text-warning'>Contact</h5>
                        {/* <p>
                            <CottageOutlinedIcon className='icon' color='' size='large' /> New York, NY
                        </p> */}
                        <p>
                            Info@beeourguests.com 
                        </p>
                    </div>
                </div>

                <hr className=' mb-4'></hr>

                <div className='row align-items-center'>
                    <div className='col-md-7 col-lg-8'>
                        <p>
                            Copyright @2022 All rights reserved
                        </p>
                    </div>

                    <div className='col-md-5 col-lg-4'>
                        <div className='text-center text-md-right'>
                            <ul className='list-unstyled list-inline'>
                                <li className='list-inline-item'>
                                    <a href='https://www.instagram.com/beeourguests/' className='btn-floating btn-sm text-white'>
                                        <InstagramIcon className='icon' color='' size='large' />
                                    </a>
                                </li>
                                <li className='list-inline-item'>
                                    <a href='https://www.facebook.com/people/Bee-Our-Guests/100087371865165/?mibextid=LQQJ4d' className='btn-floating btn-sm text-white'>
                                        <FacebookIcon className='icon' color='' size='large' />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
