import React from 'react'
import { useNavigate } from 'react-router-dom'
import BedTwoToneIcon from '@mui/icons-material/BedTwoTone';
import BathtubTwoToneIcon from '@mui/icons-material/BathtubTwoTone';
import HailTwoToneIcon from '@mui/icons-material/HailTwoTone';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import '../styles/PropertyLogoAmenities.css'

const PropertyLogoAmenities = ({ guests, bedrooms, bathrooms, name, id, location }) => {
    const navigate = useNavigate()

    // const handleClick = () => {
    //     navigate(`/properties/${id}`, { state: { id, name, images: { main, additional }, description, googleMapsLocation } })
    // }

    return (
        <div className='card-container'>
            <h2 className='amenities-text'>Come enjoy your stay at {name}</h2>
        <div className="logo-card card" key={id}>
        {/* <img src={main} className="card-img-top" alt={name} onClick={() => handleClick()}/> */}
            <div className="propertyAmenities card-body">
                {/* <h5 className="card-title-property">{name}</h5> */}
                <br/>
                {/* <span className='iconNumbers'><LocationOnTwoToneIcon className='cardIcon' color='' size='large'></LocationOnTwoToneIcon>{location}</span> */}
                <br/>
                <br/>
                <br/>
                <div className='icons-container'>
                    <div className='iconNumberz'>
                        <HailTwoToneIcon className='cardIconz' color='' size='large'/>
                        <span className='icon-text'>{guests}</span>
                        <span className='amenitiesText'>Guests</span>
                    </div>
                    <div className='iconNumberz'>
                        <BedTwoToneIcon className='cardIconz' color='' size='large'/>
                        <span className='icon-text'>{bedrooms}</span>
                        <span className='amenitiesText'>Bedrooms</span>
                    </div>
                    <div className='iconNumberz'>
                        <BathtubTwoToneIcon className='cardIconz' color='' size='large' />
                        <span className='icon-text'>{bathrooms}</span>
                        <span className='amenitiesText'>Bathrooms</span>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default PropertyLogoAmenities