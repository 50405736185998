import React from 'react'
import '../styles/About.css'
import Contact from './Contact'
import Footer from './Footer'
import Navbar from './Navbar'

const About = () => {
    return (
        <>
            <Navbar/>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className="container about-container mt-5">
                <h3 className="about-us-header text-center mb-5">About Our Company</h3>
                <hr className='about-hr'/>
                <p className="text font-weight-light text-secondary">
                    We are a family-run business offering beautiful, fully-furnished homes for travelers looking for a comfortable and enjoyable stay. Our apartments are located in the heart of different cities, allowing guests to easily explore all it is to offer.
                    Each of our apartments is uniquely decorated and equipped with all the amenities you need to feel at home, including a fully-equipped kitchen, comfortable beds, and a spacious living area. We also provide fresh linens and towels, as well as high-speed Wi-Fi and a smart TV for your entertainment.
                </p>
                <p className="text font-weight-light text-secondary">
                    In addition to our cozy homes, we also offer concierge services to help make your stay as enjoyable as possible. Whether you need restaurant recommendations or help with booking tickets for local attractions, our team is here to assist you.
                    We take pride in providing a clean and comfortable environment for our guests and strive to make sure that your stay with us is unforgettable. We look forward to hosting you on your next trip!
                </p>
                <hr/>
                <Contact />
            </div>
            <footer className='about-footer'>
                <Footer/>
            </footer> 
        </>
    )
}

export default About