import React from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import '../styles/PropertyShowpage.css'
import Footer from './Footer'
import Navbar from './Navbar'
import SearchDates from './SearchDates'
import MapsContainer from './MapsContainer'
import PropertyLogoAmenities from './PropertyLogoAmenities'
import FeaturesAndAmenities from './FeaturesAndAmenities'
import Contact from './Contact'

const PropertyShowPage = () => {
    const { state } = useLocation()
    const navigate = useNavigate();

    const handleContactClick = () => {
        navigate('/contact');
    }
    
    return (
        <>
        <Navbar/>
        <div className="container property-container my-5">
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className='text-container'>
                <h3 className='properties-text'>{state.name}</h3>
            </div>
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
                </div>
                <div className="carousel-inner">

                    <div className="carousel-item active">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img src={state.images.main} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[0]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[1]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[2]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[3]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[4]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[5]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[6]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[7]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[8]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[9]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[10]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[11]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[12]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[13]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[14]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[15]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[16]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[17]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[18]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[19]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[20]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[21]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[22]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[23]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[24]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                            <div className='col-lg-4'>
                                <img src={state.images.additional[25]} className="d-block w-100 image-margin" alt={state.name}/>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <hr/>
            <div className='elements-container'>
                <div className='propertyLogoAmenities'>
                    <PropertyLogoAmenities
                        name={state.name}
                        bathrooms={state.bathrooms}
                        bedrooms={state.bedrooms}
                        guests={state.guests}
                        location={state.location}
                    />
                </div>
                <br/>
                <br/>

            </div>
            <div>
                <FeaturesAndAmenities 
                    amenities={state.amenities}
                    id={state.id}
                />
            </div>
            <div className='searchDates-container'>
                {state.bookingLink ? (
                    <a href={state.bookingLink} target="_blank" rel="noopener noreferrer" className='booking-form-button'>
                        Book now
                    </a>
                ) : (
                        <button className='booking-form-button'>
                            Contact us for Booking
                        </button>
            )}
                    {/* <SearchDates 
                        name={state.name}
                    /> */}
            </div>
            <br/>
            <br/>
            <br/>
            <hr/>
            <p className='property-description'>{state.description}</p>
            <br/>
            <hr/>
            <Contact />
            {/* <MapsContainer
                googleMapsLocation={state.googleMapsLocation}
            /> */}
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <Footer/>
        </>
    )
}

export default PropertyShowPage

// <div className="card-group">
//                         <div className="card" key={state.id}>
//                             <img src={state.images.main} className="card-img" alt={state.name}/>
//                         </div>

//                         <div className="card" key={state.id}>
//                             <img src={state.images.additional[0]} className="card-img" alt={state.name}/>
//                         </div>

//                         <div className="card" key={state.id}>
//                             <img src={state.images.additional[2]} className="card-img" alt={state.name}/>
//                         </div>

//                         <div className="card" key={state.id}>
//                             <img src={state.images.additional[3]} className="card-img" alt={state.name}/>
//                         </div>

//                         <div className="card" key={state.id}>
//                             <img src={state.images.additional[4]} className="card-img" alt={state.name}/>
//                         </div>

//                         <div className="card" key={state.id}>
//                             <img src={state.images.additional[5]} className="card-img" alt={state.name}/>
//                         </div>

//                         <div className="card" key={state.id}>
//                             <img src={state.images.additional[6]} className="card-img" alt={state.name}/>
//                         </div>

//                         <div className="card" key={state.id}>
//                             <img src={state.images.additional[7]} className="card-img" alt={state.name}/>
//                         </div>

//                         <div className="card" key={state.id}>
//                             <img src={state.images.additional[8]} className="card-img" alt={state.name}/>
//                         </div>
//                     </div>