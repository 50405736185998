import './App.css';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import PropertiesContainer from './components/PropertiesContainer';

function App() {
  return (
      <>
          <Navbar />
          <Home />
          <hr></hr>
          <PropertiesContainer />
          <hr></hr>
          <Contact />
          <Footer />
      </>
  )
}

export default App
