import { Modal } from 'react-bootstrap';
import '../styles/AmenitiesModal.css'

const AmenitiesModal = ({ show, handleCloseModal, remainingIcons }) => {
    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title className='modal-title'>Features and Amenities</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {remainingIcons.map((amenity, index) => (
                    <div key={index}>
                        {amenity}
                    </div>
            ))}
            </Modal.Body>
            <Modal.Footer>
                <button className='modalButton' onClick={handleCloseModal}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

export default AmenitiesModal;
