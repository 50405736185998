import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Contact from './Contact'
import '../styles/PropertyPage.css'


import flHouse from '../images/fl-images/fl-house.webp'
import flOutdoor from '../images/fl-images/fl-outdoor.webp'
import flLivingroom from '../images/fl-images/fl-livingroom.webp'
import flDiningRoom from '../images/fl-images/flDiningroom.webp'
import flDiningKitchen from '../images/fl-images/fldiningkitchen.webp'
import flMasterbedroom from '../images/fl-images/fl-master-bedroom.webp'
import flBunkBeds from '../images/fl-images/flbunkbeds.webp'
import flPool2 from '../images/fl-images/flPool2.webp'
import fLKitchen from '../images/fl-images/fl-islandsink.webp'
import fLBathroom from '../images/fl-images/flbathroom.webp'
import flGuestBedRoom from '../images/fl-images/flguestbedroom.webp'
import flGameRoom from '../images/fl-images/flgameroom.webp'


import corfuHouse from '../images/gr-images/poolSide.png'
import corfuHouseOutdoor from '../images/gr-images/corfuHouseOutdoor.png'
import corfuDriveway from '../images/gr-images/corfuDriveway.jpg'
import corfuBalcony from '../images/gr-images/corfuBalcony.jpg'
import corfuPool from '../images/gr-images/corfuPool.jpg'
import corfuPoolView from '../images/gr-images/corfuPoolWithView.jpg'
import corfuKitchen from '../images/gr-images/corfuKitchen.jpg'
import corfuGuestBedroom from '../images/gr-images/corfuGuesetBed.png'
import corfuMasterBedroom from '../images/gr-images/corfuMasterBed.png'
import corfuGuestBedroom2 from '../images/gr-images/corfuGestBedroom2.png'
import corfuGuestBedroom3 from '../images/gr-images/corfuGuestBedroom3.jpg'
import corfuBathroom from '../images/gr-images/corfuBathroom.jpg'


import paHouse from '../images/pa-images/pa-nicepic.webp'
import paLivingRoom from '../images/pa-images/pa-nicepic.webp'
import paOutside from '../images/pa-images/paTrees.webp'
import paKitchen from '../images/pa-images/pa-livingkitchen.webp'
import paKitchen2 from '../images/pa-images/pa-kitchen.webp'
import paFireplace from '../images/pa-images/pa-fireplace.webp'
import paMasterBedroom from '../images/pa-images/pa-masterbedroom.webp'
import paBedroom from '../images/pa-images/pa-bedroom.webp'
import paDiningRoom from '../images/pa-images/pa-diningroom.webp'
import paGuestroom from '../images/pa-images/pa-guestroom.webp'
import paBathroom1 from '../images/pa-images/paBathroom.webp'
import paOutDoorView from '../images/pa-images/pa-background.webp'


import paHouse2LivingRoom from '../images/pa2-images/paHouse2LivingRoom.webp'
import paHouse2HouseFrontView from '../images/pa2-images/pahouse2OutdoorFrontView.webp'
import paHouse2SideView from '../images/pa2-images/paHouse2SideView.webp'
import paHouse2OutdoorView from '../images/pa2-images/paOutdoorView.webp'
import paHouse2Patio from '../images/pa2-images/paHouse2Patio.webp'
import paHouse2PatioChairs from '../images/pa2-images/paHouse2PatioChairs.webp'
import paHouse2KitchenSideView from '../images/pa2-images/paHouse2SideViewKitchen.webp'
import paHouse2KitchenFrontView from '../images/pa2-images/paHouse2KitchenFrontView.webp'
import paHouse2DiningRoom from '../images/pa2-images/paHouse2DiningRoom.webp'
import paHouseLivingDiningRoom from '../images/pa2-images/paHouse2LivingDiningRoom.webp'
import paHouse2MasterBedroom from '../images/pa2-images/paHouse2MasterBedroom.webp'
import paHouse2Guesbedroom1 from '../images/pa2-images/paHouse2GuestBedroom1.webp'
import paHouse2Guesbedroom2 from '../images/pa2-images/paHouse2Guestbedroom2.webp'
import paHouse2Guesbedroom3 from '../images/pa2-images/paHouse2GuestBedroom3.webp'
import paHouse2Guesbedroom4 from '../images/pa2-images/paHouse2GuestBedroom4.webp'
import paHouse2MasterBathroom from '../images/pa2-images/paHouse2MasterBathroom.webp'
import paHouseGuestBathroom from '../images/pa2-images/paHouse2GuestBathroom.webp'
import paHouse2MasterBathroom2 from '../images/pa2-images/paHouseMasterBathroom2.webp'
import paHouseGameroom1 from '../images/pa2-images/paHouseGameroom1.webp'
import paHouseGameroom2 from '../images/pa2-images/paHouseGameroom2.webp'
import paHouseGameroom3Arcade from '../images/pa2-images/paHouseGameroomArcade.webp'
import paHouseLivingRoom2 from '../images/pa2-images/paHouse2Fireplace.webp'
import paHouse2JacuzziView from '../images/pa2-images/paHouse2JacuzziView.webp'
import paHouse2Jacuzzi from '../images/pa2-images/paHouse2Jacuzzi.webp'


import nyHouseWholeHouseOutdoor from '../images/windhamny-images/pa3WholeHouseOutdoorView.webp'
import nyhouse3sideview2 from '../images/windhamny-images/pahouse3sideview2.webp'
import nyhouse3Patio from '../images/windhamny-images/pahouse3Patio.webp'
import nyhouse3frontpatio from '../images/windhamny-images/pahouse3frontpatio.webp'
import nyhouse3kitchenandlivingroom from '../images/windhamny-images/pahouse3kitchenandlivingroom.webp'
import nyhouse3kitchendining from '../images/windhamny-images/pahouse3kitchendining.webp'
import nyhouse3livingroom from '../images/windhamny-images/pahouse3livingroom.webp'
import nyhouse3livingroom2 from '../images/windhamny-images/pahouse3livingroom2.webp'
import nyhouse3livingroomandgameroom from '../images/windhamny-images/pahouse3livingroomandgameroom.webp'
import nyhouse3livingroomgameroomsideview from '../images/windhamny-images/pahouse3livingroomgameroomsideview.webp'
import nyhouse3livingroomgameroomkitchenview from '../images/windhamny-images/pahouse3livingroomgameroomkitchenview.webp'
import nyhouse3secondkitchen from '../images/windhamny-images/pahouse3secondkitchen.webp'
import nyhouse3bar from '../images/windhamny-images/pahouse3bar.jpg'
import nyhouse3hallway from '../images/windhamny-images/pahouse3hallway.webp'
import nyhouse3bedroom from '../images/windhamny-images/pahouse3bedroom.webp'
import nyhouse3secondbedroom from '../images/windhamny-images/pahouse3secondbedroom.webp'
import nyhouse3bedroomsideview from '../images/windhamny-images/pahouse3bedroomsideview.webp'
import nyhouse3guestbedroom from '../images/windhamny-images/pahouse3guestbedroom.jpg'
import nyhouse3bathroom from '../images/windhamny-images/pahouse3bathroom.webp'
import nyhouse3bedroom3bathroom from '../images/windhamny-images/pahouse3bedroom3bathroom.webp'
import nyhouse3guestbathroom from '../images/windhamny-images/pahouse3guestbathroom.webp'


import crespoPAMainImg from '../images/crescoPA-images/crespoPAMainImg.webp'
import CrescoPAWholeHouseOutdoorView from '../images/crescoPA-images/CrescoPAWholeHouseOutdoorView.webp'
import CrescoPAOutsideGarageView from '../images/crescoPA-images/CrescoPAOutsideGarageView.webp'
import CrescoPAPatio from '../images/crescoPA-images/CrescoPAPatio.webp'
import CrescoPAPatio2 from '../images/crescoPA-images/CrescoPAPatio2.jpeg'
import CrescoPAOutdoorActivities from '../images/crescoPA-images/CrescoPAOutdoorActivities.jpeg'
import CrescoPALivingroomTopView from '../images/crescoPA-images/CrescoPALivingroomTopView.webp'
import CrescoPALivingroom3 from '../images/crescoPA-images/CrescoPALivingroom3.webp'
import CrescoPaLivingRoom1 from '../images/crescoPA-images/CrescoPaLivingRoom1.webp'
import CrescoPAKitchen1 from '../images/crescoPA-images/CrescoPAKitchen1.webp'
import CrescoPADiningRoom from '../images/crescoPA-images/CrescoPADiningRoom.webp'
import CrescoPADiningRoom2 from '../images/crescoPA-images/CrescoPADiningRoom2.jpeg'
import CrescoPAMasterBedroom from '../images/crescoPA-images/CrescoPAMasterBedroom.webp'
import CrescoPAMasterBathroom from '../images/crescoPA-images/CrescoPAMasterBathroom.webp'
import CrescoPAMasterBathroom2 from '../images/crescoPA-images/CrescoPAMasterBathroom2.webp'
import CrescoPABathroomJacuzzi from '../images/crescoPA-images/CrescoPABathroomJacuzzi.webp'
import CrescoPAGuestBedroom1 from '../images/crescoPA-images/CrescoPAGuestBedroom1.webp'
import CrescoPAGuestbedroom4 from '../images/crescoPA-images/CrescoPAGuestbedroom4.webp'
import CrescoPABedroom2 from '../images/crescoPA-images/CrescoPABedroom2.webp'
import CrescoPABathroom1 from '../images/crescoPA-images/CrescoPABathroom1.webp'
import CrescoPAUpstairsLounge from '../images/crescoPA-images/CrescoPAUpstairsLounge.jpeg'
import CrescoPAGuestBedroom3 from '../images/crescoPA-images/CrescoPAGuestBedroom3.webp'
import CrescoPAGuestbedroom7 from '../images/crescoPA-images/CrescoPAGuestbedroom7.webp'
import CrescoPAGuestbedroom5 from '../images/crescoPA-images/CrescoPAGuestbedroom5.webp'
import CrescoPAGameroom from '../images/crescoPA-images/CrescoPAGameroom.webp'
import CrescoPAJacuzzi from '../images/crescoPA-images/CrescoPAJacuzzi.webp'


import corfuAptMain from '../images/gr-images/corfuApartment/corfu-apt-main.webp'
import corfuApartmentBalcony from '../images/gr-images/corfuApartment/corfu-apt-balconyview.webp'
import corfuApartmentDecor from '../images/gr-images/corfuApartment/corfu-apt-decor.webp'
import corfuApartmentKitchen from '../images/gr-images/corfuApartment/corfu-apt-kitchen.webp'
import corfuApartmentKitchen2 from '../images/gr-images/corfuApartment/corfu-apt-kitchen-2.webp'
import corfuApartmentKitchen3 from '../images/gr-images/corfuApartment/corfu-apt-kitchen-3.webp'
import corfuApartmentKitchen4 from '../images/gr-images/corfuApartment/corfu-apt-kitchen-4.webp'
import corfuApartmentDiningRoom from '../images/gr-images/corfuApartment/corfu-apt-diningroom.webp'
import corfuApartmentLivingRoom from '../images/gr-images/corfuApartment/corfu-apt-livingroom.webp'
import corfuApartmentLivingRoom2 from '../images/gr-images/corfuApartment/corfu-apt-livingroom2.webp'
import corfuApartmentBedroom1 from '../images/gr-images/corfuApartment/corfu-apt-bedroom1.webp'
import corfuApartmentBedroom1v2 from '../images/gr-images/corfuApartment/corfu-apt-bedroom1.2.webp'
import corfuApartmentBedroom1v3 from '../images/gr-images/corfuApartment/corfu-apt-bedroom1.3.webp'
import corfuApartmentBedroom1v4 from '../images/gr-images/corfuApartment/corfu-apt-bedroom1.4.webp'
import corfuApartmentBedroom2 from '../images/gr-images/corfuApartment/corfu-apt-bedroom2.webp'
import corfuApartmentBedroom2v2 from '../images/gr-images/corfuApartment/corfu-apt-bedroom2.1.webp'
import corfuApartmentBedroom2v3 from '../images/gr-images/corfuApartment/corfu-apt-bedroom2.3.webp'
import corfuApartmentBedroom2v4 from '../images/gr-images/corfuApartment/corfu-apt-bedroom2.4.webp'
import corfuApartmentBedroom3 from '../images/gr-images/corfuApartment/corfu-apt-bedroom3.webp'
import corfuApartmentBedroom3v1 from '../images/gr-images/corfuApartment/corfu-apt-bedroom3.1.webp'
import corfuApartmentBedroom3v3 from '../images/gr-images/corfuApartment/corfu-apt-bedroom3.3.webp'
import corfuApartmentBathroom from '../images/gr-images/corfuApartment/corfu-apt-bathroom.webp'
import corfuApartmentBathroom2 from '../images/gr-images/corfuApartment/corfu-apt-bathroom2.webp'

import livingroom1 from '../images/gr-images/ermones-home/livingroom1.webp'
import livingroom2 from '../images/gr-images/ermones-home/livingroom2.webp'
import livingroom3 from '../images/gr-images/ermones-home/livingroom3.webp' 
import kitchen1 from '../images/gr-images/ermones-home/kitchen1.webp'
import kitchen2 from '../images/gr-images/ermones-home/kitchen2.webp'
import stairs2ndFloor from '../images/gr-images/ermones-home/stairs2ndfloor.webp'
import bedroom1 from '../images/gr-images/ermones-home/bedroom1.webp'
import bedroom1v2 from '../images/gr-images/ermones-home/bedroom1.2.webp'
import bedroom1v3 from '../images/gr-images/ermones-home/bedroom1.3.webp'
import bedroom1v4 from '../images/gr-images/ermones-home/bedroom1.4.webp'
import bedroom1Closet from '../images/gr-images/ermones-home/bedroom1.5closet.webp'
import bathroom1 from '../images/gr-images/ermones-home/bathroom1.webp'
import bedroom2 from '../images/gr-images/ermones-home/bedroom2.webp'
import bedroom2v2 from '../images/gr-images/ermones-home/bedroom2.1.webp'
import bathroom2 from '../images/gr-images/ermones-home/bathroom2.webp'
import bathroom2v2 from '../images/gr-images/ermones-home/bathroom2.2.webp'
import bedroom3 from '../images/gr-images/ermones-home/bedroom3.webp'
import bedroom3v2 from '../images/gr-images/ermones-home/bedroom3.2.webp'
import bathroom3 from '../images/gr-images/ermones-home/bathroom3.webp'
import bathroom3v3 from '../images/gr-images/ermones-home/bathroom3.3.webp'
import pool1 from '../images/gr-images/ermones-home/pool1.webp'
import pool2 from '../images/gr-images/ermones-home/pool2.webp'
import pool4 from '../images/gr-images/ermones-home/pool4.webp'


import madieraFullHousePoolView from '../images/134-madiera-images/2.jpg'
import madieraFullHouseView from '../images/134-madiera-images/5.jpg'
import madieraFullHouseFrontYard from '../images/134-madiera-images/TERRA.jpg'
import madieraDiningRoomSide from '../images/134-madiera-images/6.jpg'
import madieraDiningRoom from '../images/134-madiera-images/7.jpg'
import madieraLivingRoom from '../images/134-madiera-images/9.jpg'
import madieraLivingRoom2 from '../images/134-madiera-images/11.jpg'
import madieraKitchen from '../images/134-madiera-images/13.jpg'
import madieraKitchen2 from '../images/134-madiera-images/14.jpg'
import madieraDiningRoom2 from '../images/134-madiera-images/16.jpg'
import madieraKitchenDining from '../images/134-madiera-images/17.jpg'
import madieraBedroom1 from '../images/134-madiera-images/26.jpg'
import madieraBedroom2 from '../images/134-madiera-images/32.jpg'
import madieraBedroom3 from '../images/134-madiera-images/38.jpg'
import madieraBedroom4 from '../images/134-madiera-images/43.jpg'
import madieraBedroom5 from '../images/134-madiera-images/47.jpg'
import madieraBathroom1 from '../images/134-madiera-images/30.jpg'
import madieraBathroom2 from '../images/134-madiera-images/41.jpg'
import madieraBasketBallCourt from '../images/134-madiera-images/7.jpg'
import madieraPool1 from '../images/134-madiera-images/52.jpg'
import madieraPool2 from '../images/134-madiera-images/55.jpg'
import madieraPool3 from '../images/134-madiera-images/DSC01898.JPG'
import madieraGameRoom from '../images/134-madiera-images/18.jpg'
import { Link } from 'react-router-dom'


const PropertyPage = ({ property }) => {
    const properties = [
        {
            id: 1,
            name: 'Camel Back, PA Town Home',
            image: `${paLivingRoom}`,
            bookingLink: 'https://www.airbnb.com/rooms/49051892?source_impression_id=p3_1688400206_Ab0LLSTu30cyw3Dv',
            description: 'This Townhome is in a prime location with close proximity to the Camelback Ski area and Camelbeach Water Park. The Village at Camelback is next to Big Pocono State Park which offers great hiking trails and unlimited outdoor experiences/activities!'
        },
        {
            id: 2,
            name: 'Family Home in Kissimmee, FL',
            image: `${flHouse}`,
            bookingLink: 'https://www.airbnb.com/rooms/724478135935475612?source_impression_id=p3_1688400259_RgE5TTXtXj9hA%2Fjj',
            description: "Large group or family looking for somewhere that’s luxurious, spacious, fun, and close to Disney? If so, then look no further. This 3-bedroom, 2-bathroom vacation home is for you." 
        },
        {
            id: 3,
            name: 'Corfu Greece Villa',
            image: `${corfuHouse}`,
            bookingLink: 'https://www.airbnb.com/rooms/822315165207559229?source_impression_id=p3_1688400300_iTTJcWCfa%2F5olCmP',
            description: 'This home is located in Greece on the island Corfu / Kerkyra. We are offering peaceful accommodation in nature, within a walking distance from Ermones beach and Corfu Golf Club. Relax by the pool and lawned garden or enjoy the al fresco dining and barbecue area by the pool in the evenings together with your family and friends.'
        },
        {
            id: 4,
            name: 'Camel Back, PA Private Home',
            image: `${paHouse2LivingRoom},`,
            description: 'If you are looking for one of the nicest, coziest homes on Camelback Mountain for your Pocono vacation this is the one! The house is centrally located in the heart of the Poconos in the award winning Northridge community.'
        },
        {
            id: 5,
            name: 'Windham NY Private Home',
            image: `${nyhouse3kitchenandlivingroom},`,
            bookingLink: 'https://www.airbnb.com/rooms/740467377841670178?source_impression_id=p3_1682622197_CqstytuwnT8xgojy', 
            description: 'Beautiful Four Bedroom 3 1/2 bath House located in New York with great mountain views , outdoor heated swim spa and outdoor lounge area . This house is conveniently located close to skiing slopes and supermarkets.'
        },
        {
            id: 6,
            name: 'Luxurious Home In The Poconos',
            image: `${crespoPAMainImg}`,
            bookingLink: 'https://www.airbnb.com/rooms/838477519627251348?source_impression_id=p3_1688400454_ndirqt8x3vUAjyDv',
            description: 'Forget your worries in this spacious and serene space. This Home is a perfect Place for your next get away. The home provides many fun activities. You can relax in the hot tub, enjoy the pool table, ping pong table, and the family games that we provide. The home is 5 minutes away mount airy casino, 15 minutes  away from camel back mountain, and 10 minutes from from the outlets. There is also many more activities near by like atv riding, horseback riding, fishing and many more !! Come and enjoy!!'
        },
        {
            id: 7,
            name: 'Corfu Apartment',
            image: `${corfuAptMain}`,
            bookingLink: 'https://www.airbnb.com/rooms/857065149396291738?source_impression_id=p3_1688400590_N6nsSK1ZppC3cUCQ',
            description: 'Keep it simple at this peaceful and centrally-located place. This home is in between Ermones Beach and Glyfada beach. Also located near the golf course and aqua land. Town is only 15 minutes away and many more attractions near by. You have a beautiful view from the balcony. Each room has a door that leads onto the balcony. The apartment is on the third floor so you have a view of the mountains and the golf course from afar.'
        },
        {
            id: 8,
            name: 'Serene Mountainside Home',
            image: `${livingroom2}`,
            description: 'Keep it simple at this peaceful and centrally-located place. This home is in between Ermones Beach and Glyfada beach. Also located near the golf course and aqua land. Town is only 15 minutes away and many more attractions near by. You have a beautiful view from the balcony. Each room has a door that leads onto the balcony. The apartment is on the third floor so you have a view of the mountains and the golf course from afar.'
        },
        {
            id: 9,
            name: '134 Madiera',
            image: `${madieraFullHousePoolView}`,
            description: 'This 134 Madiera is a luxurious 4 bedroom'
        }
    ]

    return (
        <>
            <Navbar/>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className="container property-container my-5" id="singleProperty">
                <div className='text-container'>
                    <h3 className='camelBack-townHome-text'>{properties[0].name}</h3>
                </div>
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <img src={paHouse} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={paOutside} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={paOutDoorView} className="d-block w-100 image-margin" alt=''/>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <img src={paKitchen} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={paKitchen2} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={paLivingRoom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <img src={paFireplace} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={paMasterBedroom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={paBedroom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                        <div className='row'>
                                <div className='col-lg-4'>
                                    <img src={paGuestroom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={paDiningRoom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={paBathroom1} className="d-block w-100 image-margin" alt=''/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <br/>
                <br/>
                <div className='bookNow-buttonCentered'>
                    <a href={properties[0].bookingLink} target="_blank" rel="noopener noreferrer" className='booking-form-button'>
                            Book now
                    </a>
                </div>
                <p className='property-description'>{properties[0].description}</p>
                <br/>
                <p>Since the Village at Camelback is next to Big Pocono State Park, there are great hiking trails close by and some of the best Pocono Mountain scenery. The Delaware Water Gap National Park is just 30 minutes away and can provide the ultimate outdoor experiences. Also, there are over 25 public golf courses in the Poconos, Crossings Outlet stores, casinos, vineyards, movie theatre & much more!
                Please note: there are 50 steps to get to the house. No ramps unfortunately. Steps inside the house as well.</p>
                <p>Newly furnished kitchen Quartz counter top and bathroom renovations, stainless steel appliances, quick access to slopes, outdoor seating on balcony and front deck. Cozy setting, fireplace and new smart TV's for your comfort which include; Disney +, FUBO for sporting events & Netflix. Espresso and coffee machine for your morning refreshness!</p>
                <hr></hr>
            </div>

            <div className='container property-container my-5'>
                <div className='text-container'>
                    <h3 className='kissimee-florida-text'>{properties[1].name}</h3>
                </div>
                <div id="carouselExampleIndicatorsTwo" className="carousel slide" data-bs-ride="true">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicatorsTwo" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicatorsTwo" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicatorsTwo" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <img src={flHouse} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={flOutdoor} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={flLivingroom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <img src={flDiningRoom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={flDiningKitchen} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={fLKitchen} className="d-block w-100 image-margin" alt=''/>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <img src={flBunkBeds} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={flMasterbedroom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={flGuestBedRoom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <img src={fLBathroom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={flGameRoom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={flPool2} className="d-block w-100 image-margin" alt=''/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsTwo" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsTwo" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                    <br/>
                    <br/>
                    <div className='bookNow-buttonCentered'>
                        <a href={properties[1].bookingLink} target="_blank" rel="noopener noreferrer" className='booking-form-button'>
                            Book now
                        </a>
                    </div>
                </div>
                <p className='property-description'>{properties[1].description}</p>
                <br/>
                <p>The living room is large and spacious. It has tiled flooring, a flat screen TV, and a large L-shaped sectional comfy couch. Guess what? The couch has a pull out option where you will have two more full beds to sleep on! This is the perfect place to relax in after a long day out at the parks. If you enjoy cooking, you’ll love this kitchen. It has plenty of workspace, quartz countertops, stainless steel appliances, and it comes fully equipped with everything you’d need to put together tasty home-cooked meals. Looking for other entertainment options? No problem. Why not head outside and take a dip in the pool to cool off? The pool area is screened-in to keep those pesky Florida bugs out. There’s also patio furniture for those who want to enjoy the Florida temperatures. And for those who want to work on their tan, there are some sunbeds dotted around the pool. There are three bedrooms. One of the bedrooms has a king-sized bed that fits four adults, the second bedroom has one queen bed and the third bedroom has a bunk bed with two full beds and one twin bed, which are great for younger children.
                Other conveniences include free Wi-Fi Internet access, optional pool heat (where available), and in-unit laundry facilities. TVs in all bedrooms and a game room for fun.</p>
                <br/>
                <p>
                Distance to Nearby Attractions:
                <br/>
                - Disney World: 6 miles
                <br/>
                - Universal Studios: 14 miles
                <br/>
                - SeaWorld: 10 miles
                <br/>
                - Falcon Fire Golf Course: 5 miles
                <br/>
                - Disney Lake Buena Vista Golf Course: 8 miles
                <br/>
                - Orlando Premium Outlets: 7 miles
                <br/>
                - Airport (Orlando International Airport): 23 miles
                <br/>
                - Medieval Times Dinner & Tournament Events: 2 miles
                </p>
            </div>

            <hr></hr>

            <div className='container property-container my-5'>
                <div className='text-container'>
                    <h3 className='corfu-text'>{properties[2].name}</h3>
                </div>
                <div id="carouselExampleIndicatorsThree" className="carousel slide" data-bs-ride="true">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicatorsThree" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicatorsThree" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicatorsThree" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <img src={corfuHouse} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={corfuHouseOutdoor} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={corfuDriveway} className="d-block w-100 image-margin" alt=''/>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <img src={corfuBalcony} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={corfuPool} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={corfuPoolView} className="d-block w-100 image-margin" alt=''/>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                        <div className='row'>
                                <div className='col-lg-4'>
                                    <img src={corfuKitchen} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={corfuGuestBedroom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={corfuMasterBedroom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                        <div className='row'>
                                <div className='col-lg-4'>
                                    <img src={corfuGuestBedroom2} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={corfuGuestBedroom3} className="d-block w-100 image-margin" alt=''/>
                                </div>
                                <div className='col-lg-4'>
                                    <img src={corfuBathroom} className="d-block w-100 image-margin" alt=''/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsThree" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsThree" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                    <br/>
                    <br/>
                    <div className='bookNow-buttonCentered'>
                        <a href={properties[2].bookingLink} target="_blank" rel="noopener noreferrer" className='booking-form-button'>
                            Book now
                        </a>
                    </div>
                </div>
                <p className='property-description'>{properties[2].description}</p>
                <br/>
                <p>This home is located in Greece on the island Corfu / Kerkyra. We are offering peaceful accommodation in nature, within a walking distance from Ermones beach and Corfu Golf Club. Relax by the pool and lawned garden or enjoy the al fresco dining and barbecue area by the pool in the evenings together with your family and friends.
                This home is a 4 bedroom villa, it is also built and equipped with high quality materials.</p>
                <br/>
                <p>
                Ground Floor:
                <br/>
                - Open plan fully equipped kitchen area with dining facilities and living room | direct access to terrace and garden area
                <br/>
                - Guest Restroom
                <br/>
                - Laundry Room
                <br/>
                - Double bedroom with bathroom (WC/shower) and direct access to pool
                <br/>
                <br/>
                First Floor:
                <br/>
                - Double bedroom with bathroom (WC/shower) and direct pool view
                <br/>
                - Two twin bedrooms with access to common balcony with garden, mountain and pool view
                <br/>
                - Full bathroom
                </p>
                Outdoors:
                <p>
                - Private pool 
                <br/>
                - Barbecue area with dining amenities
                <br/>
                - Lawn Garden
                <br/>
                - Private parking
                </p>
                <hr/>

                <div className="container property-container my-5">
                    <div className='text-container'>
                        <h3 className='camelBack-privateHome-text'>{properties[3].name}</h3>
                    </div>
                    <div id="carouselExampleIndicatorsFour" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide-to="5" aria-label="Slide 6"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide-to="6" aria-label="Slide 7"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2HouseFrontView} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2SideView} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2OutdoorView} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2Patio} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2PatioChairs} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2KitchenSideView} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2KitchenFrontView} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2DiningRoom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouseLivingDiningRoom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2MasterBedroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2Guesbedroom1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2Guesbedroom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2Guesbedroom3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2Guesbedroom4} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2MasterBathroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={paHouseGuestBathroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2MasterBathroom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouseGameroom1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={paHouseGameroom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouseGameroom3Arcade} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouseLivingRoom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2JacuzziView} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={paHouse2Jacuzzi} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsFour" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                        <br/>
                        <br/>
                        <div className='bookNow-buttonCentered'>
                            <Link to='/contact'>
                                <button className='booking-form-button'>Contact us for Booking</button>
                            </Link>
                        </div>
                    </div>
                    <p className='property-description'>{properties[3].description}</p>
                    <br/>
                    <p>The Space
                        <p>
                            Located in Northridge Camelback Village. This is a full service rental with amenities galore
                            - Nicely equipped Kitchen with Kenmore Elite appliances
                            - Toaster Oven
                            - Coffee Maker
                            - Microwave.. and many more
                        </p>
                    </p>
                    <p>Family Room
                        <p>
                            Their is a cozy sunken Family Room which is adjacent to the Kitchen so you can entertain all of your guests.
                            - Wood Burning Fireplace 
                            - 60 inch LCD TV 
                            - Stereo System
                            - Very comfortable seating for all your family & guests to relax and unwind from a day of tubing, skiing, and other Pocono adventures.</p>
                    </p>
                    <p>Bedrooms 
                        <p>
                            Upstairs are two large bedrooms with their OWN full Private bathrooms. A Jacuzzi tub is in the master bedroom with a king size bed.
                            On the main level there are two more bedrooms with their own charm and character. One has a rustic decor with a bunk bed made out of oak and a Full bathroom.
                            The other bedroom is quite unique as well with its own private staircase to a large bedroom with TV and a comfortable bed with trundle. This bedroom also has a twin bed.
                        </p>
                    </p>
                    <p>
                    The house has a full finished walk out basement which is great for entertaining. Play games, watch TV or just sit and relax. The outside of the home has a very large deck located off the back of the house with incredible views of Camelback Mountain and Nature. There is also a completely enclosed screened in porch attached to the home. The grounds of the surrounding property are majestic and very impressive. It is very quiet and tranquil with a lot of privacy. Nature is abundant. Designated work space available. Newly added outdoor hot tub/ jacuzzi for your enjoyment!
                    </p>
                    <hr></hr>
                </div>

                <div className="container property-container my-5">
                    <div className='text-container'>
                        <h3 className='windham-nyProperty-text'>{properties[4].name}</h3>
                    </div>
                    <div id="carouselExampleIndicatorsFive" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide-to="5" aria-label="Slide 6"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={nyHouseWholeHouseOutdoor} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3sideview2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3Patio} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3frontpatio} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3kitchenandlivingroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3kitchendining} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3livingroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3livingroom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3livingroomandgameroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3livingroomgameroomsideview} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3livingroomgameroomkitchenview} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3secondkitchen} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3bar} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3hallway} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3bedroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3secondbedroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3bedroomsideview} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3guestbedroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3bathroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3bedroom3bathroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={nyhouse3guestbathroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsFive" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    <br/>
                    <br/>
                    <div className='bookNow-buttonCentered'>
                        <a href={properties[4].bookingLink} target="_blank" rel="noopener noreferrer" className='booking-form-button'>
                            Book now
                        </a>
                    </div>
                    <p className='property-description'>{properties[4].description}</p>
                    <br/>
                    <p>The location is perfect in the winter, from skiing, sledding, snowboarding and many more winter activities near by. In the fall, there is plenty of places to go hiking, and in the spring/summer be sure to check out the nearby swimming holes, more hiking, antiquing, local farms , and vineyards.</p>
                    <hr></hr>
                </div>

                <div className="container property-container my-5">
                    <div className='text-container'>
                        <h3 className='camelBack-townHome-text'>{properties[5].name}</h3>
                    </div>
                    <div id="carouselExampleIndicatorsSix" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide-to="5" aria-label="Slide 6"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide-to="6" aria-label="Slide 7"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide-to="7" aria-label="Slide 8"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide-to="8" aria-label="Slide 9"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={crespoPAMainImg} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAWholeHouseOutdoorView} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAOutsideGarageView} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAPatio} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAPatio2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAOutdoorActivities} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPALivingroomTopView} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPALivingroom3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPaLivingRoom1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAKitchen1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPADiningRoom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPADiningRoom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAMasterBedroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAMasterBathroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAMasterBathroom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPABathroomJacuzzi} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAGuestBedroom1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAGuestbedroom4} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPABedroom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPABathroom1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAUpstairsLounge} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAGuestBedroom3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAGuestbedroom7} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAGuestbedroom5} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAGameroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={CrescoPAJacuzzi} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    {/* <div className='col-lg-4'>
                                        <img src={CrescoPAGuestbedroom4} className="d-block w-100 image-margin" alt=''/>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsSix" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <br/>
                <br/>
                <div className='bookNow-buttonCentered'>
                    <a href={properties[5].bookingLink} target="_blank" rel="noopener noreferrer" className='booking-form-button'>
                        Book now
                    </a>
                </div>
                <p className='property-description'>{properties[5].description}</p>
                <br/>
                <hr></hr>
                </div>

                <div className="container property-container my-5">
                    <div className='text-container'>
                        <h3 className='corfuApartment-text'>{properties[6].name}</h3>
                    </div>
                    <div id="carouselExampleIndicatorsSeven" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide-to="5" aria-label="Slide 6"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide-to="6" aria-label="Slide 7"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide-to="7" aria-label="Slide 8"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide-to="8" aria-label="Slide 9"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={corfuAptMain} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBalcony} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentDecor} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentKitchen} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentKitchen2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentKitchen3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentKitchen4} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentDiningRoom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentLivingRoom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentLivingRoom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBedroom1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBedroom1v2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBedroom1v3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBedroom1v4} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBedroom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBedroom2v2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBedroom2v3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBedroom2v4} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBedroom3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBedroom3v1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBedroom3v3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBathroom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={corfuApartmentBathroom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsSeven" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <br/>
                <br/>
                <div className='bookNow-buttonCentered'>
                    <a href={properties[6].bookingLink} target="_blank" rel="noopener noreferrer" className='booking-form-button'>
                        Book now
                    </a>
                </div>
                <p className='property-description'>{properties[6].description}</p>
                <br/>
                <hr></hr>
                </div>

                <div className="container property-container my-5">
                    <div className='text-container'>
                        <h3 className='corfuApartment-text'>{properties[7].name}</h3>
                    </div>
                    <div id="carouselExampleIndicatorsEight" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide-to="5" aria-label="Slide 6"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide-to="6" aria-label="Slide 7"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide-to="7" aria-label="Slide 8"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide-to="8" aria-label="Slide 9"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={livingroom1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={livingroom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={livingroom3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={kitchen1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={kitchen2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={stairs2ndFloor} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={bedroom1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={bedroom1v2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={bedroom1v3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={bedroom1v4} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={bedroom1Closet} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={bathroom1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={bedroom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={bedroom2v2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={bathroom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={bathroom2v2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={bedroom3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={bedroom3v2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={bathroom3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={bathroom3v3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={pool1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={pool2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={pool4} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsEight" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <br/>
                <br/>
                <div className='bookNow-buttonCentered'>
                    <Link to='/contact'>
                        <button className='booking-form-button'>Contact us for Booking</button>
                    </Link>
                </div>
                <p className='property-description'>{properties[7].description}</p>
                <br/>
                <hr></hr>
                </div>

                {/* <div className="container property-container my-5">
                    <div className='text-container'>
                        <h3 className='madiera-text'>{properties[8].name}</h3>
                    </div>
                    <div id="carouselExampleIndicatorsNine" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide-to="5" aria-label="Slide 6"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide-to="6" aria-label="Slide 7"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide-to="7" aria-label="Slide 8"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide-to="8" aria-label="Slide 9"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={madieraFullHousePoolView} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraFullHouseView} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraFullHouseFrontYard} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={madieraDiningRoomSide} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraDiningRoom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraLivingRoom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={madieraLivingRoom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraKitchen} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraKitchen2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={madieraDiningRoom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraKitchenDining} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraBedroom1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={madieraBedroom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraBedroom3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraBedroom4} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={madieraBedroom5} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraBathroom1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraBathroom2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={madieraBasketBallCourt} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraPool1} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraPool2} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={madieraPool3} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={madieraGameRoom} className="d-block w-100 image-margin" alt=''/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsNine" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <p className='property-description'>{properties[8].description}</p>
                <br/>
                <hr></hr>
                </div> */}
                <Contact />
            </div>
            <Footer/>
        </>
    )
}

export default PropertyPage