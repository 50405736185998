import React, { useState } from 'react'
import FlatwareIcon from '@mui/icons-material/Flatware'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import TvIcon from '@mui/icons-material/Tv'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService'
import FireplaceIcon from '@mui/icons-material/Fireplace'
import WifiIcon from '@mui/icons-material/Wifi'
import LandscapeIcon from '@mui/icons-material/Landscape'
import PoolIcon from '@mui/icons-material/Pool'
import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined'
import DownhillSkiingOutlinedIcon from '@mui/icons-material/DownhillSkiingOutlined'
import ImportantDevicesSharpIcon from '@mui/icons-material/ImportantDevicesSharp'
import SanitizerIcon from '@mui/icons-material/Sanitizer'
import SportsIcon from '@mui/icons-material/Sports'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import SoapIcon from '@mui/icons-material/Soap'
import DryCleaningIcon from '@mui/icons-material/DryCleaning'
import SportsEsportsIcon from '@mui/icons-material/SportsEsports'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import CribIcon from '@mui/icons-material/Crib'
import ToysIcon from '@mui/icons-material/Toys'
import CrisisAlertSharpIcon from '@mui/icons-material/CrisisAlertSharp'
import FireExtinguisherSharpIcon from '@mui/icons-material/FireExtinguisherSharp'
import MedicalServicesSharpIcon from '@mui/icons-material/MedicalServicesSharp'
import KitchenSharpIcon from '@mui/icons-material/KitchenSharp'
import MicrowaveSharpIcon from '@mui/icons-material/MicrowaveSharp'
import CoffeeMakerSharpIcon from '@mui/icons-material/CoffeeMakerSharp'
import BreakfastDiningSharpIcon from '@mui/icons-material/BreakfastDiningSharp'
import CookieSharpIcon from '@mui/icons-material/CookieSharp'
import BalconyIcon from '@mui/icons-material/Balcony'
import DeckIcon from '@mui/icons-material/Deck'
import HotTubIcon from '@mui/icons-material/HotTub'
import PetsIcon from '@mui/icons-material/Pets'
import SportsTennisIcon from '@mui/icons-material/SportsTennis'
import ShowerRoundedIcon from '@mui/icons-material/ShowerRounded'
import OutdoorGrillRoundedIcon from '@mui/icons-material/OutdoorGrillRounded'
import HotTubRoundedIcon from '@mui/icons-material/HotTubRounded'
import WavesIcon from '@mui/icons-material/Waves'
import MediaBluetoothOnIcon from '@mui/icons-material/MediaBluetoothOn'
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant'
import NestCamWiredStandIcon from '@mui/icons-material/NestCamWiredStand';
import WaterIcon from '@mui/icons-material/Water';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import ChairAltRoundedIcon from '@mui/icons-material/ChairAltRounded';
import BabyChangingStationRoundedIcon from '@mui/icons-material/BabyChangingStationRounded';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import KitchenRoundedIcon from '@mui/icons-material/KitchenRounded';
import FenceOutlinedIcon from '@mui/icons-material/FenceOutlined';
import BeachAccessRoundedIcon from '@mui/icons-material/BeachAccessRounded';
import EventSeatRoundedIcon from '@mui/icons-material/EventSeatRounded';
import IronOutlinedIcon from '@mui/icons-material/IronOutlined';

import AmenitiesModal from './AmenitiesModal'

import '../styles/FeaturesAndAmenities.css'
// LocalParking, Tv, AcUnit, FitnessCenter, Pool, SportsEsports, HotTub, Fireplace, LocalLibrary, Toys, ViewComfy, LocalLaundryService, Work, Xbox, FreeBreakfast, LocalHospital, LocalGroceryStore, LocalDrink, OutDoorGrill } from '@mui/icons-material'

const FeaturesAndAmenities = ({ amenities, id }) => {
    const [showRemaining, setShowRemaining] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const firstTenAmenities = amenities.slice(0, 10);
    const remainingAmenities = amenities.slice(10);

    const iconsDescriptions = {
        'Outdoor shower': 'Outdoor shower',
        'Wifi': 'Wifi',
        'Lake access': 'Lake access',
        'Mountain View': 'Mountain view',
        'Kitchen': 'Kitchen',
        'Shared indoor pool': 'Shared indoor pool',
        'Private pool': 'Private pool',
        'Parking': 'Parking',
        'TV': 'TV',
        'Air conditioning': 'Air conditioning',
        'Central A/C': 'Central A/C',
        'Washer': 'Washer',
        'Dryer': 'Dryer',
        'BBQ grill': 'BBQ grill',
        'Fireplace': 'Fireplace',
        'Pets allowed': 'Pets allowed',
        'Board games': 'Board games',
        'Ping pong table': 'Ping pong table',
        'Skiing': 'Skiing',
        'Workspace': 'Workspace',
        'Shampoo': 'Shampoo',
        'Hair Dryer': 'Hair Dryer',
        'Cleaning Products': 'Cleaning Products',
        'Body Soap': 'Body Soap',
        'Essentials': 'Essentials',
        'Xbox One': 'Xbox One',
        'Free weights': 'Free weights',
        'Books': 'Books',
        'Baby crib': 'Baby crib',
        'Childrens books and toys': 'Childrens books and toys',
        'Smoke alarm': 'Smoke alarm',
        'Carbon monoxide alarm': 'Carbon monoxide alarm',
        'Fire extinguisher': 'Fire extinguisher',
        'First aid kit': 'First aid kit',
        'Refrigerator': 'Refrigerator',
        'Microwave': 'Microwave',
        'Dishes and silverware': 'Dishes and silverware',
        'Coffee Maker': 'Coffee Maker',
        'Toaster': 'Toaster',
        'Baking sheet': 'Baking sheet',
        'Balcony': 'Balcony',
        'Outdoor furniture': 'Outdoor furniture',
        'Shared sauna': 'Shared sauna',
        'Shared gym': 'Shared gym',
        'Bluetooth Sound System': 'Bluetooth Sound System',
        'Private hot tub': 'Private hot tub',
        'Shared outdoor pool': 'Shared outdoor pool',
        'Beach access': 'Beach access',
        'Security cameras': 'Security cameras',
        'Hair dryer': 'Hair dryer',
        'Bed linens': 'Bed linens',
        'Clothing storage': 'Clothing storage',
        'High chair': 'High chair',
        'Baby bath': 'Baby bath',
        'Babysitter recommendations': 'Babysitter recommendations',
        'Cooking basics': 'Cooking basics',
        'Freezer': 'Freezer',
        'Electric stove': 'Electric stove',
        'Private backyard': 'Private backyard',
        'Beach essentials': 'Beach essentials',
        'Sun loungers': 'Sun loungers',
        'Iron': 'Iron',
        'Dishwasher': 'Dishwaser',
    }

    const firstTenIcons = firstTenAmenities.map((amenity, index) => {
        const iconsDescription = iconsDescriptions[amenity]
        switch (amenity) {
            case 'Lake access':
                return (
                    <div key={index} className='amenity-item-container'>
                        <WavesIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
                case 'Security cameras':
                    return (
                        <div key={index} className='amenity-item-container'>
                            <NestCamWiredStandIcon />
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                        </div>
                    )
            case 'Mountain View':
                return (
                    <div key={index} className='amenity-item-container'>
                        <LandscapeIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Kitchen':
                return (
                    <div key={index} className='amenity-item-container'>
                        <FlatwareIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Shared indoor pool':
                return (
                    <div key={index} className='amenity-item-container'>
                        <PoolIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Private pool':
                return (
                    <div key={index} className='amenity-item-container'>
                        <PoolIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Parking':
                return (
                    <div key={index} className='amenity-item-container'>
                        <DirectionsCarIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'TV':
                return (
                    <div key={index} className='amenity-item-container'>
                        <TvIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Air conditioning':
                return (
                    <div key={index} className='amenity-item-container'>
                        <AcUnitIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Central A/C':
                return (
                    <div key={index} className='amenity-item-container'>
                        <AcUnitIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                ) 
            case 'Washer':
                return (
                    <div key={index} className='amenity-item-container'>
                        <LocalLaundryServiceIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Dryer':
                return (
                    <div key={index} className='amenity-item-container'>
                        <LocalLaundryServiceIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Fireplace':
                return (
                    <div key={index} className='amenity-item-container'>
                        <FireplaceIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Wifi':
                return (
                    <div key={index} className='amenity-item-container'>
                        <WifiIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Pets allowed':
                return (
                    <div key={index} className='amenity-item-container'>
                        <PetsIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Workspace':
                return (
                    <div key={index} className='amenity-item-container'>
                        <ImportantDevicesSharpIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Private hot tub':
                return (
                    <div key={index} className='amenity-item-container'>
                        <HotTubRoundedIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Outdoor shower':
                return (
                    <div key={index} className='amenity-item-container'>
                        <ShowerRoundedIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'BBQ grill':
                return (
                    <div key={index} className='amenity-item-container'>
                        <OutdoorGrillRoundedIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Shared outdoor pool':
                return (
                    <div key={index} className='amenity-item-container'>
                        <PoolIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Pool table':
                return (
                    <div key={index} className='amenity-item-container'>
                        <TableRestaurantIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Smoke alarm':
                return (
                    <div key={index} className='amenity-item-container'>
                        <CrisisAlertSharpIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Carbon monoxide alarm':
                return (
                    <div key={index} className='amenity-item-container'>
                        <CrisisAlertSharpIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            default:
                return null;
        }
    })

    const remainingIcons = remainingAmenities.map((amenity, index) => {
        const iconsDescription = iconsDescriptions[amenity]
        switch (amenity) {
            case 'BBQ grill':
                return (
                    <div key={index} className='amenity-container'>
                        <OutdoorGrillRoundedIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Fireplace':
                return (
                    <div key={index} className='amenity-container'>
                        <FireplaceIcon  />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Pets allowed':
                return (
                    <div key={index} className='amenity-container'>
                        <PetsIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Board games':
                return (
                    <div key={index} className='amenity-container'>
                        <CasinoOutlinedIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Ping pong table':    
                return (
                    <div key={index} className='amenity-container'>
                        <SportsTennisIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Skiing':
                return (
                    <div key={index} className='amenity-container'>
                        <DownhillSkiingOutlinedIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Workspace':
                return (
                    <div key={index} className='amenity-container'>
                        <ImportantDevicesSharpIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Shampoo':  
                return (
                    <div key={index} className='amenity-container'>
                        <SanitizerIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Hair Dryer':
                return (
                    <div key={index} className='amenity-container'>
                        <SportsIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Cleaning Products':
                return (
                    <div key={index} className='amenity-container'>
                        <CleaningServicesIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Body Soap':
                return (
                    <div key={index} className='amenity-container'>
                        <SoapIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Essentials':
                return (
                    <div key={index} className='amenity-container'>
                        <DryCleaningIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Xbox One':
                return (
                    <div key={index} className='amenity-container'>
                        <SportsEsportsIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Free weights':
                return (
                    <div key={index} className='amenity-container'>
                        <FitnessCenterIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Books':
                return (
                    <div key={index} className='amenity-container'>
                        <MenuBookIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Baby crib':
                return (
                    <div key={index} className='amenity-container'>
                        <CribIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Childrens books and toys':
                return (
                    <div key={index} className='amenity-container'>
                        <ToysIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Smoke alarm':
                return (
                    <div key={index} className='amenity-container'>
                        <CrisisAlertSharpIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Carbon monoxide alarm':
                return (
                    <div key={index} className='amenity-container'>
                        <CrisisAlertSharpIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Fire extinguisher':
            return (
                <div key={index} className='amenity-container'>
                    <FireExtinguisherSharpIcon />
                    <p className='iconDescriptionText'>{iconsDescription}</p>
                </div>
            )
            case 'First aid kit':
                return (
                    <div key={index} className='amenity-container'>
                        <MedicalServicesSharpIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Refrigerator':
                return (
                    <div key={index} className='amenity-container'>
                        <KitchenSharpIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Microwave':
                return (
                    <div key={index} className='amenity-container'>
                        <MicrowaveSharpIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Dishes and silverware': 
                return (
                    <div key={index} className='amenity-container'>
                        <FlatwareIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Coffee Maker':
                return (
                    <div key={index} className='amenity-container'>
                        <CoffeeMakerSharpIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Toaster':
                return (
                    <div key={index} className='amenity-container'>
                        <BreakfastDiningSharpIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Baking sheet':
                return (
                    <div key={index} className='amenity-container'>
                        <CookieSharpIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Balcony':
                return (
                    <div key={index} className='amenity-container'>
                        <BalconyIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Outdoor furniture':
                return (
                    <div key={index} className='amenity-container'>
                        <DeckIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Shared sauna':
                return (
                    <div key={index} className='amenity-container'>
                        <HotTubIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Shared gym':
                return (
                    <div key={index} className='amenity-container'>
                        <FitnessCenterIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Bluetooth Sound System':
                return (
                    <div key={index} className='amenity-container'>
                        <MediaBluetoothOnIcon />
                        <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Beach access':
                return (
                    <div key={index} className='amenity-container'>
                        <WaterIcon />
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Bed linens':
                return (
                    <div key={index} className='amenity-container'>
                        < DescriptionOutlinedIcon/>
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Clothing storage':
                return (
                    <div key={index} className='amenity-container'>
                        < StorageOutlinedIcon/>
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'High chair':
                return (
                    <div key={index} className='amenity-container'>
                        <ChairAltRoundedIcon />
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Baby bath':
                return (
                    <div key={index} className='amenity-container'>
                        <BabyChangingStationRoundedIcon />
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Babysitter recommendations':
                return (
                    <div key={index} className='amenity-container'>
                        <SupervisorAccountRoundedIcon />
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
                )
            case 'Cooking basics':
                return (
                    <div key={index} className='amenity-container'>
                        <FlatwareIcon />
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
            )
            case 'Freezer':
                return (
                    <div key={index} className='amenity-container'>
                        <KitchenRoundedIcon />
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
            )
            case 'Private backyard':
                return (
                    <div key={index} className='amenity-container'>
                        <FenceOutlinedIcon />
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
            )
            case 'Beach essentials':
                return (
                    <div key={index} className='amenity-container'>
                        <BeachAccessRoundedIcon />
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
            )
            case 'Sun loungers':
                return (
                    <div key={index} className='amenity-container'>
                        <EventSeatRoundedIcon />
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
            )
            case 'Iron':
                return (
                    <div key={index} className='amenity-container'>
                        <IronOutlinedIcon />
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
            )
            case 'Dishwasher':
                return (
                    <div key={index} className='amenity-container'>
                        <LocalLaundryServiceIcon />
                            <p className='iconDescriptionText'>{iconsDescription}</p>
                    </div>
            )
            default:
                return null
        }
    })
        
    const handleCloseModal = () => {
        setShowModal(false);
    }
    
    const handleShowModal = () => {
        setShowModal(true)
    }    

    return (
        <div className='featuredAmenities'>
            <h5 className='featuresAmenitiesText'>Features and Amenities</h5>
            <div className='iconsz-container'>
                <div className='first-five-icons'>
                    {firstTenIcons.slice(0, 5).map((icon, index) => (
                        <div key={index} className='icon-wrapper'>
                            {icon}
                        </div>
                    ))}
                </div>
                <div className='last-five-icons'>
                    {firstTenIcons.slice(5).map((icon, index) => (
                        <div key={index} className='icon-wrapper'>
                            {icon}
                            <p className='iconDescriptionText'>{iconsDescriptions[remainingIcons[index]]}</p>
                        </div>
                    ))}
                </div>
            </div>
            {remainingIcons.length > 0 && (
                <button className='modalButton' onClick={handleShowModal}>
                    Show more amenities
                </button>
            )}
            <AmenitiesModal
                show={showModal}
                handleCloseModal={handleCloseModal}
                remainingIcons={remainingIcons}
            />
        </div>
    )
}

export default FeaturesAndAmenities;

