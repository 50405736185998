import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import PropertyPage from './components/PropertyPage';
import PropertyShowPage from './components/PropertyShowPage';
import About from './components/About';
import Contact from './components/Contact';
import Management from './components/Management';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "properties",
    element: <PropertyPage/>
  },
  {
    path: "about",
    element: <About/>
  },
  {
    path: "properties/:id",
    element: <PropertyShowPage/>
  },
  {
    path: "contact",
    element: <Contact/>
  },
  {
    path: "management",
    element: <Management/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
