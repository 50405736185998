import React from 'react'
import '../styles/PropertiesContainer.css'
import PropertyListings from './PropertyListings'
import Contact from './Contact'


import flHouse from '../images/fl-images/fl-house.webp'
import flOutdoor from '../images/fl-images/fl-outdoor.webp'
import flLivingroom from '../images/fl-images/fl-livingroom.webp'
import flLivingRoomSideView from '../images/fl-images/flLivingRoomSideView.webp'
import flDiningRoom from '../images/fl-images/flDiningroom.webp'
import flDiningKitchen from '../images/fl-images/fldiningkitchen.webp'
import flKitchenSideView from '../images/fl-images/kitchenSideView.webp'
import flDiningTable from '../images/fl-images/diningtable.webp'
import flMasterbedroom from '../images/fl-images/flMasterBedroom1.3.webp'
import flMasterBedroom1 from '../images/fl-images/masterBedroom1.2.webp'
import flMasterBathroom1 from '../images/fl-images/flMasterBedroomBathtub.webp'
import flBedroom2SideView from '../images/fl-images/flBedroom2SideView.webp'
import flShower from '../images/fl-images/flShower.webp'
import flBunkBeds from '../images/fl-images/flbunkbeds.webp'
import flBunkBedShower from '../images/fl-images/flBunkBedBathroom.webp'
import flPool2 from '../images/fl-images/flPool2.webp'
import fLKitchen from '../images/fl-images/fl-islandsink.webp'
import fLBathroom from '../images/fl-images/flbathroom.webp'
import flGuestBedRoom from '../images/fl-images/flguestbedroom.webp'
import flGameRoom from '../images/fl-images/flgameroom.webp'
import flOutdoorFurniture from '../images/fl-images/flPoolFurniture.webp'
import flOutdoorDining from '../images/fl-images/flOutdoorDining.webp'
import flBbq from '../images/fl-images/flBBQ.webp'
import flLoungeChairs from '../images/fl-images/flLoungeChairs.webp'


import corfuHouseMain from '../images/gr-images/corfuHouseMain.webp'
import corfuHouse from '../images/gr-images/poolSide.png'
import corfuHousePool from '../images/gr-images/corfuHousePool.webp'
import corfuHouseDrivewayEntrance from '../images/gr-images/corfuHouseDrivewayEntrance.webp'
import corfuLivingroom1 from '../images/gr-images/corfuLivingroom1.jpeg'
import corfuLivingroom2 from '../images/gr-images/corfuLivingroom2.jpeg'
import corfuLivingroom3 from '../images/gr-images/corfuLivingroom3.webp'
import corfuKitchen1 from '../images/gr-images/corfuKitchen1.webp'
import corfuKitchen2 from '../images/gr-images/corfuKitchen2.webp'
import corfuBedroom1 from '../images/gr-images/corfuBedroom1.webp'
import corfuBedroom2 from '../images/gr-images/corfuBedroom2.webp'
import corfuBalcony1 from '../images/gr-images/corfuBalcony1.webp'
import corfuBedroom3 from '../images/gr-images/corfuBedroom3.webp'
import corfuMasterBedroom1 from '../images/gr-images/corfuMasterbedroom.webp'
import corfuMasterBedroom2 from '../images/gr-images/corfuMasterBedroom2.webp'
import corfuMasterBedroom3 from '../images/gr-images/corfuMasterBedroom3.webp'
import corfuMasterBedroom4 from '../images/gr-images/corfuMasterBedroom4.webp'
import corfuBathroom1 from '../images/gr-images/corfuBathroom1.webp'
import corfuShower from '../images/gr-images/corfuShower.webp'
import corfuShower2 from '../images/gr-images/corfuShower2.webp'
import corfuBathroom2 from '../images/gr-images/corfuBathroom2.webp'
import corfuMasterBathroom from '../images/gr-images/corfuMasterBathroom.webp'
import corfuGuestBedroom2 from '../images/gr-images/corfuGestBedroom2.png'
import corfuGuestBedroom3 from '../images/gr-images/corfuGuestBedroom3.jpg'


import paHouse from '../images/pa-images/pa-nicepic.webp'
import paFrontHouse from '../images/pa-images/paHouse.webp'
import paPatioView from '../images/pa-images/patioView.webp'
import paSnowOutdoor from '../images/pa-images/paSnowOutdoor.webp'
import paLivingRoom from '../images/pa-images/pa-nicepic.webp'
import paOutside from '../images/pa-images/paTrees.webp'
import paKitchen from '../images/pa-images/pa-livingkitchen.webp'
import diningRoomKitchen from '../images/pa-images/pa-diningroom.webp'
import paDiningRoomTableView from '../images/pa-images/paDiningRoomTableView.webp'
import paCoffee from '../images/pa-images/paCoffeeMakers.webp'
import paKitchen2 from '../images/pa-images/pa-kitchen.webp'
import paKitchenFullView from '../images/pa-images/paKitchenFullViewNice.webp'
import paFireplace from '../images/pa-images/pa-fireplace.webp'
import paSkyView from '../images/pa-images/paSkyView.webp'
import paMasterBedroom from '../images/pa-images/pa-masterbedroom.webp'
import paMasterBedroomv2 from '../images/pa-images/paMasterBedroom1.2.webp'
import paBedroom from '../images/pa-images/pa-bedroom.webp'
import paBedroomv2 from '../images/pa-images/paBedroom2.1.webp'
import paBedroom3 from '../images/pa-images/paBedroom3.webp'
import paBathroom1 from '../images/pa-images/paBathroom.webp'
import paBathroom2 from '../images/pa-images/paBathroom2.webp'
import paOutDoorView from '../images/pa-images/pa-background.webp'
import paWasherDryer from '../images/pa-images/washerDryer.webp'
import paActivities from '../images/pa-images/activities.webp'


import paHouse2LivingRoom from '../images/pa2-images/paHouse2LivingRoom.webp'
import paHouse2HouseFrontView from '../images/pa2-images/pahouse2OutdoorFrontView.webp'
import paHouse2FrontHouseView from '../images/pa-images/paHouse2FrontHouseView.webp'
import paHouse2OutdoorView from '../images/pa2-images/paOutdoorView.webp'
import paHouse2Patio from '../images/pa2-images/paHouse2Patio.webp'
import paHouse2PatioChairs from '../images/pa2-images/paHouse2PatioChairs.webp'
import paHouse2KitchenSideView from '../images/pa2-images/paHouse2SideViewKitchen.webp'
import paHouse2KitchenFrontView from '../images/pa2-images/paHouse2KitchenFrontView.webp'
import paHouse2DiningRoom from '../images/pa2-images/paHouse2DiningRoom.webp'
import paHouseLivingDiningRoom from '../images/pa2-images/paHouse2LivingDiningRoom.webp'
import paHouse2MasterBedroom from '../images/pa2-images/paHouse2MasterBedroom.webp'
import paHouse2Guesbedroom1 from '../images/pa2-images/paHouse2GuestBedroom1.webp'
import paHouse2Guesbedroom2 from '../images/pa2-images/paHouse2Guestbedroom2.webp'
import paHouse2Guesbedroom3 from '../images/pa2-images/paHouse2GuestBedroom3.webp'
import paHouse2Guesbedroom4 from '../images/pa2-images/paHouse2GuestBedroom4.webp'
import paHouseBunkBedsSideView from '../images/pa2-images/paBunkBedsSideview.webp'
import paHouse2GreyBeds from '../images/pa2-images/paHouseGreyBedroomSheets.webp'
import paHouse2MasterBathroom from '../images/pa2-images/paHouse2MasterBathroom.webp'
import paBathroomJacuzzi from '../images/pa-images/paHouse2BathroomJacuzzi.webp'
import paHouseGuestBathroom from '../images/pa2-images/paHouse2GuestBathroom.webp'
import paHouse2MasterBathroom2 from '../images/pa2-images/paHouseMasterBathroom2.webp'
import paHouseGameroom1 from '../images/pa2-images/paHouseGameroom1.webp'
import paHouseGameroom2 from '../images/pa2-images/paHouseGameroom2.webp'
import paHouseLivingRoom2 from '../images/pa2-images/paHouse2Fireplace.webp'
import paHouse2JacuzziView from '../images/pa2-images/paHouse2JacuzziView.webp'
import paHouse2Jacuzzi from '../images/pa2-images/paHouse2Jacuzzi.webp'


import nyHouseWholeHouseOutdoor from '../images/windhamny-images/pa3WholeHouseOutdoorView.webp'
import nyhouse3sideview2 from '../images/windhamny-images/pahouse3sideview2.webp'
import nyhousestreetview from '../images/windhamny-images/pahouse3StreeetView.webp'
import nyhouse3Patio from '../images/windhamny-images/pahouse3Patio.webp'
import nyhouse3frontpatio from '../images/windhamny-images/pahouse3frontpatio.webp'
import nyhouse3kitchenandlivingroom from '../images/windhamny-images/pahouse3kitchenandlivingroom.webp'
import nyhouse3kitchendining from '../images/windhamny-images/pahouse3kitchendining.webp'
import nyhouse3livingroom from '../images/windhamny-images/pahouse3livingroom.webp'
import nyhouse3livingroom2 from '../images/windhamny-images/pahouse3livingroom2.webp'
import nyhouse3livingroomandgameroom from '../images/windhamny-images/pahouse3livingroomandgameroom.webp'
import nyhouse3livingroomgameroomsideview from '../images/windhamny-images/pahouse3livingroomgameroomsideview.webp'
import nyhouse3livingroomgameroomkitchenview from '../images/windhamny-images/pahouse3livingroomgameroomkitchenview.webp'
import nyhouse3secondkitchen from '../images/windhamny-images/pahouse3secondkitchen.webp'
import nyhouse3bar from '../images/windhamny-images/pahouse3bar.jpg'
import nyhouse3hallway from '../images/windhamny-images/pahouse3hallway.webp'
import nyhouse3bedroom from '../images/windhamny-images/pahouse3bedroom.webp'
import nyhouse3secondbedroom from '../images/windhamny-images/pahouse3secondbedroom.webp'
import nyhouse3bedroomsideview from '../images/windhamny-images/pahouse3bedroomsideview.webp'
import nyhouse3guestbedroomfrontview from '../images/windhamny-images/pahouse3bunkbedguestroom.webp'
import nyhouse3guestbedroom from '../images/windhamny-images/pahouse3guestbedroom.jpg'
import nyhouse3bathroom from '../images/windhamny-images/pahouse3bathroom.webp'
import nyhouse3bedroom3bathroom from '../images/windhamny-images/pahouse3bedroom3bathroom.webp'
import nyhouse3guestbathroom from '../images/windhamny-images/pahouse3guestbathroom.webp'
import nyhouse3guestbathrooom2 from '../images/windhamny-images/pahouse3guestbathrooom2.webp'


import crespoPAMainImg from '../images/crescoPA-images/crespoPAMainImg.webp'
import CrescoPAWholeHouseOutdoorView from '../images/crescoPA-images/CrescoPAWholeHouseOutdoorView.webp'
import CrescoPAOutsideGarageView from '../images/crescoPA-images/CrescoPAOutsideGarageView.webp'
import CrescoPAPatio from '../images/crescoPA-images/CrescoPAPatio.webp'
import CrescoPAPatio2 from '../images/crescoPA-images/CrescoPAPatio2.jpeg'
import CrescoPAOutdoorActivities from '../images/crescoPA-images/CrescoPAOutdoorActivities.jpeg'
import CrescoPALivingroomTopView from '../images/crescoPA-images/CrescoPALivingroomTopView.webp'
import CrescoPALivingroom3 from '../images/crescoPA-images/CrescoPALivingroom3.webp'
import CrescoPaLivingRoom1 from '../images/crescoPA-images/CrescoPaLivingRoom1.webp'
import CrescoPAKitchen1 from '../images/crescoPA-images/CrescoPAKitchen1.webp'
import CrescoPADiningRoom from '../images/crescoPA-images/CrescoPADiningRoom.webp'
import CrescoPADiningRoom2 from '../images/crescoPA-images/CrescoPADiningRoom2.jpeg'
import CrescoPAMasterBedroom from '../images/crescoPA-images/CrescoPAMasterBedroom.webp'
import CrescoPAMasterBathroom from '../images/crescoPA-images/CrescoPAMasterBathroom.webp'
import CrescoPAMasterBathroom2 from '../images/crescoPA-images/CrescoPAMasterBathroom2.webp'
import CrescoPABathroomJacuzzi from '../images/crescoPA-images/CrescoPABathroomJacuzzi.webp'
import CrescoPAGuestBedroom1 from '../images/crescoPA-images/CrescoPAGuestBedroom1.webp'
import CrescoPAGuestbedroom4 from '../images/crescoPA-images/CrescoPAGuestbedroom4.webp'
import CrescoPABedroom2 from '../images/crescoPA-images/CrescoPABedroom2.webp'
import CrescoPABathroom1 from '../images/crescoPA-images/CrescoPABathroom1.webp'
import CrescoPAUpstairsLounge from '../images/crescoPA-images/CrescoPAUpstairsLounge.jpeg'
import CrescoPAGuestBedroom3 from '../images/crescoPA-images/CrescoPAGuestBedroom3.webp'
import CrescoPAGuestbedroom7 from '../images/crescoPA-images/CrescoPAGuestbedroom7.webp'
import CrescoPAGuestbedroom5 from '../images/crescoPA-images/CrescoPAGuestbedroom5.webp'
import CrescoPAGameroom from '../images/crescoPA-images/CrescoPAGameroom.webp'
import CrescoPAJacuzzi from '../images/crescoPA-images/CrescoPAJacuzzi.webp'


import corfuAptMain from '../images/gr-images/corfuApartment/corfu-apt-main.webp'
import corfuApartmentBalcony from '../images/gr-images/corfuApartment/corfu-apt-balconyview.webp'
import corfuApartmentDecor from '../images/gr-images/corfuApartment/corfu-apt-decor.webp'
import corfuApartmentKitchen from '../images/gr-images/corfuApartment/corfu-apt-kitchen.webp'
import corfuApartmentKitchen2 from '../images/gr-images/corfuApartment/corfu-apt-kitchen-2.webp'
import corfuApartmentKitchen3 from '../images/gr-images/corfuApartment/corfu-apt-kitchen-3.webp'
import corfuApartmentKitchen4 from '../images/gr-images/corfuApartment/corfu-apt-kitchen-4.webp'
import corfuApartmentDiningRoom from '../images/gr-images/corfuApartment/corfu-apt-diningroom.webp'
import corfuApartmentLivingRoom from '../images/gr-images/corfuApartment/corfu-apt-livingroom.webp'
import corfuApartmentLivingRoom2 from '../images/gr-images/corfuApartment/corfu-apt-livingroom2.webp'
import corfuApartmentBedroom1 from '../images/gr-images/corfuApartment/corfu-apt-bedroom1.webp'
import corfuApartmentBedroom1v2 from '../images/gr-images/corfuApartment/corfu-apt-bedroom1.2.webp'
import corfuApartmentBedroom1v3 from '../images/gr-images/corfuApartment/corfu-apt-bedroom1.3.webp'
import corfuApartmentBedroom1v4 from '../images/gr-images/corfuApartment/corfu-apt-bedroom1.4.webp'
import corfuApartmentBedroom2 from '../images/gr-images/corfuApartment/corfu-apt-bedroom2.webp'
import corfuApartmentBedroom2v2 from '../images/gr-images/corfuApartment/corfu-apt-bedroom2.1.webp'
import corfuApartmentBedroom2v3 from '../images/gr-images/corfuApartment/corfu-apt-bedroom2.3.webp'
import corfuApartmentBedroom2v4 from '../images/gr-images/corfuApartment/corfu-apt-bedroom2.4.webp'
import corfuApartmentBedroom3 from '../images/gr-images/corfuApartment/corfu-apt-bedroom3.webp'
import corfuApartmentBedroom3v1 from '../images/gr-images/corfuApartment/corfu-apt-bedroom3.1.webp'
import corfuApartmentBedroom3v3 from '../images/gr-images/corfuApartment/corfu-apt-bedroom3.3.webp'
import corfuApartmentBathroom from '../images/gr-images/corfuApartment/corfu-apt-bathroom.webp'
import corfuApartmentBathroom2 from '../images/gr-images/corfuApartment/corfu-apt-bathroom2.webp'
import corfuApartmentGolf from '../images/gr-images/corfuApartment/corfuApartmentGolf.webp'


import livingroom1 from '../images/gr-images/ermones-home/livingroom1.webp'
import livingroom2 from '../images/gr-images/ermones-home/livingroom2.webp'
import livingroom3 from '../images/gr-images/ermones-home/livingroom3.webp' 
import kitchen1 from '../images/gr-images/ermones-home/kitchen1.webp'
import kitchen2 from '../images/gr-images/ermones-home/kitchen2.webp'
import stairs2ndFloor from '../images/gr-images/ermones-home/stairs2ndfloor.webp'
import bedroom1 from '../images/gr-images/ermones-home/bedroom1.webp'
import bedroom1v2 from '../images/gr-images/ermones-home/bedroom1.2.webp'
import bedroom1v3 from '../images/gr-images/ermones-home/bedroom1.3.webp'
import bedroom1v4 from '../images/gr-images/ermones-home/bedroom1.4.webp'
import bedroom1Closet from '../images/gr-images/ermones-home/bedroom1.5closet.webp'
import bathroom1 from '../images/gr-images/ermones-home/bathroom1.webp'
import bedroom2 from '../images/gr-images/ermones-home/bedroom2.webp'
import bedroom2v2 from '../images/gr-images/ermones-home/bedroom2.1.webp'
import bathroom2 from '../images/gr-images/ermones-home/bathroom2.webp'
import bathroom2v2 from '../images/gr-images/ermones-home/bathroom2.2.webp'
import bedroom3 from '../images/gr-images/ermones-home/bedroom3.webp'
import bedroom3v2 from '../images/gr-images/ermones-home/bedroom3.2.webp'
import bathroom3 from '../images/gr-images/ermones-home/bathroom3.webp'
import bathroom3v3 from '../images/gr-images/ermones-home/bathroom3.3.webp'
import pool1 from '../images/gr-images/ermones-home/pool1.webp'
import pool2 from '../images/gr-images/ermones-home/pool2.webp'
import pool4 from '../images/gr-images/ermones-home/pool4.webp'
import outdoorfurniture from '../images/gr-images/ermones-home/outdoorfurniture.webp'
import Footer from './Footer'


// import madieraFullHousePoolView from '../images/134-madiera-images/2.jpg'
// import madieraFullHouseView from '../images/134-madiera-images/5.jpg'
// import madieraFullHouseFrontYard from '../images/134-madiera-images/TERRA.jpg'
// import madieraDiningRoomSide from '../images/134-madiera-images/6.jpg'
// import madieraDiningRoom from '../images/134-madiera-images/7.jpg'
// import madieraLivingRoom from '../images/134-madiera-images/9.jpg'
// import madieraLivingRoom2 from '../images/134-madiera-images/11.jpg'
// import madieraKitchen from '../images/134-madiera-images/13.jpg'
// import madieraKitchen2 from '../images/134-madiera-images/14.jpg'
// import madieraDiningRoom2 from '../images/134-madiera-images/16.jpg'
// import madieraKitchenDining from '../images/134-madiera-images/17.jpg'
// import madieraBedroom1 from '../images/134-madiera-images/26.jpg'
// import madieraBedroom2 from '../images/134-madiera-images/32.jpg'
// import madieraBedroom3 from '../images/134-madiera-images/38.jpg'
// import madieraBedroom4 from '../images/134-madiera-images/43.jpg'
// import madieraBedroom5 from '../images/134-madiera-images/47.jpg'
// import madieraBathroom1 from '../images/134-madiera-images/30.jpg'
// import madieraBathroom2 from '../images/134-madiera-images/41.jpg'
// import madieraBasketBallCourt from '../images/134-madiera-images/7.jpg'
// import madieraPool1 from '../images/134-madiera-images/52.jpg'
// import madieraPool2 from '../images/134-madiera-images/55.jpg'
// import madieraPool3 from '../images/134-madiera-images/DSC01898.JPG'
// import madieraGameRoom from '../images/134-madiera-images/18.jpg'


function PropertiesContainer() {
    const properties = [
        {
            id: 0,
            name: 'Camel Back Town Home',
            guests: 7,
            bedrooms: 3,
            bathrooms: 2,
            location: 'Poconos',
            googleMapsLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3355.695158389544!2d-97.09668258481872!3d32.74728438098091!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e7d86c8884727%3A0x6d1e60e88c6a9df8!2sAT%26T%20Stadium!5e0!3m2!1sen!2sus!4v1682372093683!5m2!1sen!2sus",
            googleMapsLocationAfterBooking: '',
            price: '',
            bookingLink: 'https://www.airbnb.com/rooms/49051892?source_impression_id=p3_1688400206_Ab0LLSTu30cyw3Dv',
            images: {
                main: `${paHouse}`,
                additional: [
                    `${paFrontHouse}`,
                    `${paSnowOutdoor}`,
                    `${paPatioView}`,
                    `${paOutside}`,
                    `${paOutDoorView}`,
                    `${paKitchen}`,
                    `${diningRoomKitchen}`,
                    `${paDiningRoomTableView}`,
                    `${paLivingRoom}`,
                    `${paKitchen2}`,
                    `${paKitchenFullView}`,
                    `${paCoffee}`,
                    `${paSkyView}`,
                    `${paFireplace}`,
                    `${paMasterBedroom}`,
                    `${paMasterBedroomv2}`,
                    `${paBedroom}`,
                    `${paBedroomv2}`,
                    `${paBedroom3}`,
                    `${paBathroom1}`,
                    `${paBathroom2}`,
                    `${paWasherDryer}`,
                    `${paActivities}`
                ],
            },
            amenities: ['Mountain View', 'Kitchen', 'Shared indoor pool', 'Parking', 'TV', 'Air conditioning', 'Washer', 'Dryer', 'Fireplace', 'Wifi', 'Pets allowed', 'Board games', 'Skiing', 'Workspace', 'Shampoo', 'Hair Dryer', 'Cleaning Products', 'Body Soap', 'Essentials', 'Xbox One', 'Free weights', 'Books', 'Baby crib', 'Childrens books and toys', 'Smoke alarm', 'Carbon monoxide alarm', 'Fire extinguisher', 'First aid kit', 'Refrigerator', 'Microwave', 'Dishes and silverware', 'Dishwasher', 'Coffee Maker', 'Toaster', 'Baking sheet', 'Balcony', 'Outdoor furniture', 'Shared sauna', 'Shared gym'],
            description: `This Townhome is in a prime location with close proximity to the Camelback Ski area and Camelbeach Water Park. The Village at Camelback is next to Big Pocono State Park which offers great hiking trails and unlimited outdoor experiences/activities!
            Enjoy your home away from home with access to; Xbox one, Netflix, Disney Plus, and FUBO tv (shows almost every single sporting event).
            Newly furnished kitchen Quartz counter top and bathroom renovations, stainless steel appliances, quick access to slopes, outdoor seating on balcony and front deck. Cozy setting, fireplace and new smart TV's for your comfort which include; Disney +, FUBO for sporting events & Netflix. Espresso and coffee machine for your morning refreshness!

            Fun games included: GIANT Jenga, Monopoly, Heads up, Checkers & Jumanji.

            Since the Village at Camelback is next to Big Pocono State Park, there are great hiking trails close by and some of the best Pocono Mountain scenery. The Delaware Water Gap National Park is just 30 minutes away and can provide the ultimate outdoor experiences. Also, there are over 25 public golf courses in the Poconos, Crossings Outlet stores, casinos, vineyards, movie theatre & much more!

            Please note: there are 50 steps to get to the house. No ramps unfortunately. Steps inside the house as well.  Guest access
            Pool, Tennis court & fitness center. 2 parking spots available for guests.
            `
        },
        {
            id: 1,
            name: 'Family Home Florida',
            guests: 13,
            bedrooms: 3,
            bathrooms: 2,
            location: 'Kissimmee',
            googleMapsLocation: '',
            googleMapsLocationAfterBooking: '',
            price: '',
            bookingLink: 'https://www.airbnb.com/rooms/724478135935475612?source_impression_id=p3_1688400259_RgE5TTXtXj9hA%2Fjj',
            images: {
                main: `${flHouse}`,
                additional: [
                    `${flOutdoor}`,
                    `${flLivingroom}`,
                    `${flLivingRoomSideView}`,
                    `${flDiningRoom}`,
                    `${flDiningKitchen}`,
                    `${fLKitchen}`,
                    `${flKitchenSideView}`,
                    `${flDiningTable}`,
                    `${flMasterbedroom}`,
                    `${flMasterBedroom1}`,
                    `${fLBathroom}`,
                    `${flMasterBathroom1}`,
                    `${flGuestBedRoom}`,
                    `${flBedroom2SideView}`,
                    `${flShower}`,
                    `${flBunkBeds}`,
                    `${flBunkBedShower}`,
                    `${flGameRoom}`,
                    `${flPool2}`,
                    `${flOutdoorFurniture}`,
                    `${flOutdoorDining}`,
                    `${flBbq}`,
                    `${flLoungeChairs}`,
                ],
            },
            amenities: ['Kitchen', 'Parking', 'Private pool', 'TV', 'Central A/C', 'Washer', 'Dryer', 'Wifi', 'Pets allowed', 'Workspace', 'Ping pong table', 'Board games', 'Shampoo', 'Hair Dryer', 'Cleaning Products', 'Body Soap', 'Essentials', 'Books', 'Baby crib', 'Smoke Alarm', 'Carbon monoxide alarm', 'Fire Extinguisher', 'First Aid Kit', 'Refrigerator', 'Microwave', 'Dishes and silverware', 'Dishwasher', 'Coffee Maker', 'Toaster', 'Baking sheet', 'Outdoor furniture'],
            description: 
            `Large group or family looking for somewhere that’s luxurious, spacious, fun, and close to Disney? If so, then look no further. 
            This 3-bedroom, 2-bathroom vacation home is for you. The living room is large and spacious. It has tiled flooring, a flat screen TV, and a large L-shaped sectional comfy couch. 
            Guess what? The couch has a pull out option where you will have two more full beds to sleep on! This is the perfect place to relax in after a long day out at the parks.\n
            If you enjoy cooking, you’ll love this kitchen. It has plenty of workspace, quartz countertops, stainless steel appliances, and it comes fully equipped with everything you’d need to put together tasty home-cooked meals.\n
            Looking for other entertainment options? No problem. Why not head outside and take a dip in the pool to cool off? The pool area is screened-in to keep those pesky Florida bugs out.\n
            There’s also patio furniture for those who want to enjoy the Florida temperatures. And for those who want to work on their tan, there are some sunbeds dotted around the pool.\n
            There are three bedrooms. One of the bedrooms has a king-sized bed that fits four adults, the second bedroom has one queen bed and the third bedroom has a bunk bed with two full beds and one twin bed, which are great for younger children.\n
            Distance to Nearby Attractions:\n
            - Disney World: 6 miles\n
            - Universal Studios: 14 miles\n
            - SeaWorld: 10 miles\n
            - Falcon Fire Golf Course: 5 miles\n
            - Disney Lake Buena Vista Golf Course: 8 miles\n
            - Orlando Premium Outlets: 7 miles\n
            - Airport (Orlando International Airport): 23 miles\n
            - Medieval Times Dinner & Tournament Events: 2 miles`
            
        },
        {
            id: 2,
            name: 'Luxurious Villa Clio',
            guests: 8,
            bedrooms: 4,
            bathrooms: 3.5,
            location: 'Corfu',
            googleMapsLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3073.6932158854042!2d19.785183131986816!3d39.6115928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!3m2!1sen!2sus!4v1682456343117!5m2!1sen!2sus",
            googleMapsLocationAfterBooking: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3073.710096271649!2d19.78612726956006!3d39.61121259394267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135b59b9fa9da54b%3A0xb0df58f4d98d181c!2sCorfu%20Golf%20Villa!5e0!3m2!1sen!2sus!4v1682456594178!5m2!1sen!2sus",
            price: '',
            bookingLink: 'https://www.airbnb.com/rooms/822315165207559229?source_impression_id=p3_1688400300_iTTJcWCfa%2F5olCmP',
            images: {
                main: `${corfuHouseMain}`,
                additional: [
                    `${corfuHousePool}`,
                    `${corfuHouseDrivewayEntrance}`,
                    `${corfuLivingroom1}`,
                    `${corfuLivingroom2}`,
                    `${corfuLivingroom3}`,
                    `${corfuKitchen1}`,
                    `${corfuKitchen2}`,
                    `${corfuBedroom1}`,
                    `${corfuBedroom2}`,
                    `${corfuBalcony1}`,
                    `${corfuBedroom3}`,
                    `${corfuGuestBedroom2}`,
                    `${corfuMasterBedroom1}`,
                    `${corfuMasterBedroom2}`,
                    `${corfuMasterBedroom3}`,
                    `${corfuMasterBedroom4}`,
                    `${corfuGuestBedroom3}`,
                    `${corfuBathroom1}`,
                    `${corfuShower}`,
                    `${corfuShower2}`,
                    `${corfuBathroom2}`,
                    `${corfuMasterBathroom}`,
                    `${corfuHouse}`,
                    `${corfuHouse}`,
                    `${corfuHouse}`,
                    `${corfuHouse}`,
                    `${corfuHouse}`,
                    `${corfuHouse}`,
                    `${corfuHouse}`,
                    `${corfuHouse}`,
                    `${corfuHouse}`,
                ],
            },
            amenities: ['Kitchen', 'Parking', 'Pets allowed', 'Washer', 'Wifi', 'Private pool', 'TV', 'Air conditioning', 'Outdoor shower', 'BBQ grill', 'Smoke alarm', 'First aid kit', 'Fire extinguisher', 'Outdoor furniture'],
            description: `
            This home is located in Greece on the island Corfu / Kerkyra. We are offering peaceful accommodation in nature, within a walking distance from Ermones beach and Corfu Golf Club. Relax by the pool and lawned garden or enjoy the al fresco dining and barbecue area by the pool in the evenings together with your family and friends.

            This home is a 4 bedroom villa, it is also built and equipped with high quality materials.
            
            Ground floor
            
            Open plan fully equipped kitchen area with dining facilities and living room | direct access to terrace and garden area
            Guest Restroom
            Laundry room
            Double bedroom with bathroom (WC/shower) and direct access to pool
            First floor
            
            Double bedroom with bathroom (WC/shower) and direct pool view
            Two twin bedrooms with access to common balcony with garden, mountain and pool view
            Full bathroom
            
            OUTDOORS
            
            Private pool
            Barbecue area with dining amenities
            Lawn Garden
            Private parking
            `
        },
        {
            id: 3,
            name: 'Camel Back Private House',
            guests: 10,
            bedrooms: 4,
            bathrooms: 3,
            location: 'Poconos',
            googleMapsLocation: '',
            googleMapsLocationAfterBooking: '',
            price: '',
            images: {
                main: `${paHouse2LivingRoom}`,
                additional: [
                    `${paHouse2HouseFrontView}`,
                    `${paHouse2FrontHouseView}`,
                    `${paHouse2Patio}`,
                    `${paHouse2PatioChairs}`,
                    `${paHouse2KitchenSideView}`,
                    `${paHouse2KitchenFrontView}`,
                    `${paHouse2DiningRoom}`,
                    `${paHouseLivingDiningRoom}`,
                    `${paHouse2MasterBedroom}`,
                    `${paHouse2Guesbedroom1}`,
                    `${paHouse2Guesbedroom4}`,
                    `${paHouse2Guesbedroom2}`,
                    `${paHouseBunkBedsSideView}`,
                    `${paHouse2Guesbedroom3}`,
                    `${paHouse2GreyBeds}`,
                    `${paHouse2MasterBathroom}`,
                    `${paHouse2MasterBathroom2}`,
                    `${paBathroomJacuzzi}`,
                    `${paHouseGuestBathroom}`,
                    `${paHouseGameroom1}`,
                    `${paHouseGameroom2}`,
                    `${paHouseLivingRoom2}`,
                    `${paHouse2JacuzziView}`,
                ],
            },
            amenities: ['Mountain View', 'Kitchen', 'Parking', 'Private hot tub', 'TV', 'Central A/C', 'Shared outdoor pool', 'Wifi', 'Pets allowed', 'Washer', 'Board games', 'Skiing', 'Books', 'Workspace', 'Iron', 'Shampoo', 'Hair Dryer', 'Cleaning Products', 'Body Soap', 'Essentials', 'Baby Crib', 'Childrens books and toys', 'Fireplace', 'Smoke Alarm', 'Carbon monoxide alarm', 'Fire extinguisher', 'First aid kit', 'Refrigerator', 'Microwave', 'Dishes and silverware', 'Dishwasher', 'Coffee Maker', 'Toaster', 'Baking sheet', 'Balcony', 'Outdoor furniture', 'BBQ grill'],    
            description: `If you are looking for one of the nicest, coziest homes on Camelback Mountain for your Pocono vacation this is the one! The house is centrally located in the heart of the Poconos in the award winning Northridge community.
            `
        },
        {
            id: 4,
            name: 'Private Home Windham NY',
            guests: 14,
            bedrooms: 4,
            bathrooms: 3.5,
            location: 'Windham New York',
            googleMapsLocation: '',
            googleMapsLocationAfterBooking: '',
            price: '',
            bookingLink: 'https://www.airbnb.com/rooms/740467377841670178?source_impression_id=p3_1682622197_CqstytuwnT8xgojy', 
            images: {
                main: `${nyHouseWholeHouseOutdoor}`,
                additional: [
                    `${nyhouse3sideview2}`,
                    `${nyhousestreetview}`,
                    `${nyhouse3Patio}`,
                    `${nyhouse3frontpatio}`,
                    `${nyhouse3kitchenandlivingroom}`,
                    `${nyhouse3kitchendining}`,
                    `${nyhouse3livingroom}`,
                    `${nyhouse3livingroom2}`,
                    `${nyhouse3livingroomandgameroom}`,
                    `${nyhouse3livingroomgameroomsideview}`,
                    `${nyhouse3livingroomgameroomkitchenview}`,
                    `${nyhouse3secondkitchen}`,
                    `${nyhouse3bar}`,
                    `${nyhouse3hallway}`,
                    `${nyhouse3secondbedroom}`,
                    `${nyhouse3bedroom}`,
                    `${nyhouse3bedroomsideview}`,
                    `${nyhouse3guestbedroomfrontview}`,
                    `${nyhouse3guestbedroom}`,
                    `${nyhouse3bathroom}`,
                    `${nyhouse3bedroom3bathroom}`,
                    `${nyhouse3guestbathroom}`,
                    `${nyhouse3guestbathrooom2}`
                ],
            },
            amenities: ['Lake access', 'Kitchen', 'Parking', 'TV', 'Pets allowed', 'Air conditioning', 'Private pool', 'Wifi', 'Workspace', 'Board games', 'Skiing',  'Bluetooth Sound System', 'Shampoo', 'Hair Dryer', 'Cleaning Products', 'Body Soap', 'Essentials', 'Books', 'Baby crib', 'Fireplace', 'Smoke Alarm', 'Carbon monoxide alarm', 'Fire extinguisher', 'First aid kit', 'Refrigerator', 'Microwave', 'Dishes and silverware', 'Dishwasher', 'Coffee Maker', 'Toaster', 'Baking sheet', 'Balcony', 'Outdoor furniture', 'Fire pit', 'BBQ grill'],    
            description: `Beautiful Four Bedroom 3 1/2 bath House located in New York with great mountain views , outdoor heated swim spa and outdoor lounge area . This house is conveniently located close to skiing slopes and supermarkets.`
        },
        {
            id: 5,
            name: 'Luxurious Home In The Poconos',
            guests: 16,
            bedrooms: 5,
            bathrooms: 4,
            location: 'Poconos',
            googleMapsLocation: '',
            googleMapsLocationAfterBooking: '',
            price: '',
            bookingLink: 'https://www.airbnb.com/rooms/838477519627251348?source_impression_id=p3_1688400454_ndirqt8x3vUAjyDv',
            images: {
                main: `${crespoPAMainImg}`,
                additional: [
                    `${CrescoPAPatio}`,
                    `${CrescoPAPatio2}`,
                    `${CrescoPAOutdoorActivities}`,
                    `${CrescoPALivingroomTopView}`,
                    `${CrescoPALivingroom3}`,
                    `${CrescoPaLivingRoom1}`,
                    `${CrescoPAKitchen1}`,
                    `${CrescoPADiningRoom}`,
                    `${CrescoPADiningRoom2}`,
                    `${CrescoPAMasterBedroom}`,
                    `${CrescoPAMasterBathroom}`,
                    `${CrescoPAMasterBathroom2}`,
                    `${CrescoPABathroomJacuzzi}`,
                    `${CrescoPAGuestBedroom1}`,
                    `${CrescoPAGuestbedroom4}`,
                    `${CrescoPABedroom2}`,
                    `${CrescoPABathroom1}`,
                    `${CrescoPAUpstairsLounge}`,
                    `${CrescoPAGuestBedroom3}`,
                    `${CrescoPAGuestbedroom7}`,
                    `${CrescoPAGuestbedroom5}`,
                    `${CrescoPAGameroom}`,
                    `${CrescoPAJacuzzi}`
                ],
            },
            amenities: ['Kitchen', 'Parking', 'TV', 'Washer', 'Dryer', 'Fireplace', 'Wifi', 'Air conditioning', 'Private hot tub', 'Dryer', 'Pool table', 'BBQ Grill', 'Board games', 'Exercise equipment', 'Mountain View', 'Workspace', 'Cleaning Products', 'Books', 'Baby Crib', 'Childrens books and toys', 'Fireplace', 'Smoke Alarm', 'Carbon monoxide alarm', 'Fire extinguisher', 'First aid kit', 'Refrigerator', 'Microwave', 'Dishes and silverware', 'Dishwasher', 'Coffee Maker', 'Toaster', 'Baking sheet', 'Outdoor furniture'],    
            description: `Forget your worries in this spacious and serene space. This Home is a perfect Place for your next get away. The home provides many fun activities. You can relax in the hot tub, enjoy the pool table, ping pong table, and the family games that we provide. 
            The home is 5 minutes away mount airy casino, 15 minutes  away from camel back mountain, and 10 minutes from from the outlets. There is also many more activities near by like atv riding, horseback riding, fishing and many more !! Come and enjoy!!`
        },
        {
            id: 6,
            name: 'Corfu Apartment',
            guests: 6,
            bedrooms: 3,
            bathrooms: 1,
            location: 'Corfu Greece',
            googleMapsLocation: '',
            googleMapsLocationAfterBooking: '',
            price: '',
            bookingLink: 'https://www.airbnb.com/rooms/857065149396291738?source_impression_id=p3_1688400590_N6nsSK1ZppC3cUCQ',
            images: {
                main: `${corfuAptMain}`,
                additional: [
                    `${corfuApartmentBalcony}`,
                    `${corfuApartmentDecor}`,
                    `${corfuApartmentKitchen}`,
                    `${corfuApartmentKitchen2}`,
                    `${corfuApartmentKitchen3}`,
                    `${corfuApartmentKitchen4}`,
                    `${corfuApartmentDiningRoom}`,
                    `${corfuApartmentLivingRoom}`,
                    `${corfuApartmentLivingRoom2}`,
                    `${corfuApartmentBedroom1}`,
                    `${corfuApartmentBedroom1v2}`,
                    `${corfuApartmentBedroom1v3}`,
                    `${corfuApartmentBedroom1v4}`,
                    `${corfuApartmentBedroom2}`,
                    `${corfuApartmentBedroom2v2}`,
                    `${corfuApartmentBedroom2v3}`,
                    `${corfuApartmentBedroom2v4}`,
                    `${corfuApartmentBedroom3}`,
                    `${corfuApartmentBedroom3v1}`,
                    `${corfuApartmentBedroom3v3}`,
                    `${corfuApartmentBathroom}`,
                    `${corfuApartmentBathroom2}`,
                    `${corfuApartmentGolf}`
                ],
            },
            amenities: ['Mountain View', 'Wifi', 'Pets allowed', 'Washer', 'Kitchen', 'Parking', 'TV', 'Air conditioning', 'Smoke alarm', 'Carbon monoxide alarm', 'Fire extinguisher', 'First aid kit'],    
            description: `Keep it simple at this peaceful and centrally-located place. This home is in between Ermones Beach and Glyfada beach. Also located near the golf course and aqua land. Town is only 15 minutes away and many more attractions near by. You have a beautiful view from the balcony. Each room has a door that leads onto the balcony. The apartment is on the third floor so you have a view of the mountains and the golf course from afar.`
        },
        {
            id: 7,
            name: 'Serene Mountainside Home',
            guests: 7,
            bedrooms: 3,
            bathrooms: 3.5,
            location: 'Ermones Greece',
            googleMapsLocation: '',
            googleMapsLocationAfterBooking: '',
            price: '',
            // bookingLink: <Contact/>,
            images: {
                main: `${livingroom2}`,
                additional: [
                    `${livingroom1}`,
                    `${livingroom3}`,
                    `${kitchen1}`,
                    `${kitchen2}`,
                    `${stairs2ndFloor}`,
                    `${bedroom1}`,
                    `${bedroom1v2}`,
                    `${bedroom1v3}`,
                    `${bedroom1v4}`,
                    `${bedroom1Closet}`,
                    `${bathroom1}`,
                    `${bedroom2}`,
                    `${bedroom2v2}`,
                    `${bathroom2}`,
                    `${bathroom2v2}`,
                    `${bedroom3}`,
                    `${bedroom3v2}`,
                    `${bathroom3}`,
                    `${bathroom3v3}`,
                    `${outdoorfurniture}`,
                    `${pool1}`,
                    `${pool2}`,
                    `${pool4}`,
                ],
            },
            amenities: ['Mountain View', 'Kitchen', 'Parking', 'Pets allowed', 'Private pool', 'Wifi', 'Security cameras', 'TV', 'Air conditioning', 'Washer', 'Beach access', 'Fire extinguisher', 'First aid kit', 'Hair Dryer', 'Cleaning Products', 'Shampoo', 'Body Soap', 'Essentials', 'Bed linens', 'Iron', 'Clothing storage', 'Baby crib', 'Childrens books and toys', 'High chair', 'Baby bath', 'Board games', 'Babysitter recommendations', 'Air conditioning', 'Security cameras', 'Fire extinguisher', 'First aid kit', 'Cooking basics', 'Dishes and silverware', 'Freezer', 'Dishwasher', 'Electric stove', 'Coffee Maker', 'Toaster', 'Private backyard', 'Beach essentials', 'Sun loungers'],    
            description: `Welcome to our charming and cozy home in the heart of Deza, Corfu (Vatos Village)! Our traditional stone house has been lovingly and newly built to offer you a comfortable and authentic Greek island experience.
            Our home is ideally located for exploring the best of Corfu, with easy access to the island's stunning beaches, enchanting town, and historic landmarks.
            We look forward to welcoming you to our home and helping you create unforgettable memories of your stay in Corfu!`
        },
        // {
        //     id: 8,
        //     name: '134 Madiera',
        //     guests: 6,
        //     bedrooms: 3,
        //     bathrooms: 1,
        //     location: '134 Madiera',
        //     googleMapsLocation: '',
        //     googleMapsLocationAfterBooking: '',
        //     price: '',
        //     images: {
        //         main: `${madieraFullHousePoolView}`,
        //         additional: [
        //             `${madieraFullHouseView}`,
        //             `${madieraFullHouseFrontYard}`,
        //             `${madieraDiningRoomSide}`,
        //             `${madieraDiningRoom}`,
        //             `${madieraLivingRoom}`,
        //             `${madieraLivingRoom2}`,
        //             `${madieraKitchen}`,
        //             `${madieraKitchen2}`,
        //             `${madieraDiningRoom2}`,
        //             `${madieraKitchenDining}`,
        //             `${madieraBedroom1}`,
        //             `${madieraBedroom2}`,
        //             `${madieraBedroom3}`,
        //             `${madieraBedroom4}`,
        //             `${madieraBedroom5}`,
        //             `${madieraBathroom1}`,
        //             `${madieraBathroom2}`,
        //             `${madieraBasketBallCourt}`,
        //             `${madieraPool1}`,
        //             `${madieraPool2}`,
        //             `${madieraPool3}`,
        //             `${madieraGameRoom}`,
        //         ],
        //     },
        //     amenities: ['Mountain View', 'Wifi', 'Pets Allowed', 'Washer', 'Kitchen', 'Parking', 'TV', 'Air conditioning', 'Smoke alarm', 'Carbon monoxide alarm', 'Fire extinguisher', 'First aid kit'],    
        //     description: `Keep it simple at this peaceful and centrally-located place. This home is in between Ermones Beach and Glyfada beach. Also located near the golf course and aqua land. Town is only 15 minutes away and many more attractions near by. You have a beautiful view from the balcony. Each room has a door that leads onto the balcony. The apartment is on the third floor so you have a view of the mountains and the golf course from afar.`
        // }
    ]

    return (
        <div className="container property-container my-5">
            <div className='text-container'>
                <h3 className='properties-text'>Featured Properties</h3>
            </div>
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="indicator active bg-dark" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"  className='indicator bg-dark' aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"  className='indicator bg-dark' aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"  className='indicator bg-dark' aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4"  className='indicator bg-dark' aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5"  className='indicator bg-dark' aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6"  className='indicator bg-dark' aria-label="Slide 7"></button>
                </div>
                    <div className="carousel-inner">
                        {properties.map((property, index) => (
                        <div className={`carousel-item ${index === 0 ? 'active':''}`}>
                            <div className="col-12">
                                <PropertyListings key={property.id} property={property} />
                            </div>
                        </div>
                        ))}
                    </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div>
            </div>
        </div>
    )
}

export default PropertiesContainer