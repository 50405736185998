import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll'
import '../styles/Navbar.css'
import logo from '../images/beeourguestslogo.png'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const toggleMenu = () =>  {
        setIsMenuOpen(!isMenuOpen)
    }
    
    return (
        <div className='navbar-container'>
            <nav className='navbar navbar-expand-lg'>
                    <RouterLink to='/' className="navbar-brand">
                        <img className='logo' src={logo} alt=''/>
                    </RouterLink>
                    <span className='brandName'>Bee Our Guests</span>
                    <div className='endcontent'>
                        <button className={`navbar-toggler ${isMenuOpen ? 'open' : ''}`} type="button" onClick={toggleMenu}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
                            <ul className="navbar-nav justify-content-center me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <RouterLink to='/' className="nav-link active" aria-current="page">Home</RouterLink>
                                </li>
                                <li className="nav-item">
                                    <RouterLink to='/properties' className="nav-link active" aria-current="page">Vacation Homes</RouterLink>
                                </li>
                                <li className="nav-item">
                                    <RouterLink to='/management' className="nav-link active" aria-current="page">Management</RouterLink>
                                </li>
                                <li className="nav-item">
                                    <RouterLink to='/about' className="nav-link active">About</RouterLink>
                                </li>
                                <li className="nav-item">
                                    <ScrollLink to='contact' smooth={true} offset={50} duration={150} className="nav-link active">Contact</ScrollLink>
                                </li>
                            </ul>
                            <div className='icons'>
                                <a href='https://www.instagram.com/beeourguests/' className='icon'>
                                    <InstagramIcon className='icon' size='large' style={{color: 'black'}}/>
                                </a>
                                <a href='https://www.facebook.com/people/Bee-Our-Guests/100087371865165/?mibextid=LQQJ4d' className='icon'>
                                    <FacebookOutlinedIcon className='icon' style={{color: 'black'}} size="large" />
                                </a>
                            </div>
                        </div>
                    </div>
            </nav>
        </div>
    ) 
}

export default Navbar

