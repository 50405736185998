import React, { useState } from 'react'
import '../styles/Contact.css'
import emailjs from 'emailjs-com';
import Footer from './Footer';
import Navbar from './Navbar';

const Contact = () => {
    const [result, showResult] = useState(false)

    const Result = () => {
        return (
            <p>Your message has been successfully sent. We will contact you soon</p>
        )
    }

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_lp12dnm', 'template_cgo2whk', e.target, 'QigxqbgUuf3-UpAUz')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
        });
        e.target.reset()
        showResult(true)

        setTimeout(() => {
            showResult(false)
        }, 5000)
    }
    
    return (
        <>
            <div className='container contact-container mt-5' id='contact'>
                <div className='text-container'>
                    <h3 className='contact-text'>Contact Us</h3>
                </div>
                <form action='' onSubmit={sendEmail}>
                    <div className="form-outline-name mb-4">
                        <input type="text" id="form4Example1" className="form-control" name="fullName" required />
                        <label className="form-label" htmlFor="form4Example1">Name</label>
                    </div>

                    <div className="form-outline-email mb-4">
                        <input type="email" id="form4Example2" className="form-control" name="email" required/>
                        <label className="form-label" htmlFor="form4Example2">Email address</label>
                    </div>

                    <div className="form-outline-message mb-4">
                        <textarea className="form-control" id="form4Example3" rows="10" name="message" required></textarea>
                        <label className="form-label" htmlFor="form4Example3">Message</label>
                    </div>
                    <button type="submit" className="btn btn-dark btn-block mb-4">Send</button>
                </form>
            </div>
        </>
    )
}

export default Contact
