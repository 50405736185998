import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/Home.css'
import flHouse from '../images/fl-images/fl-house.webp'
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';

function Home() {
    return (
                <div className='home'>
                    <div className='header-background'>
                        <img className='img img-fluid w-100' src={flHouse} alt=''/>
                        <div className='header-text'>
                            <p className='image-text'>Book Your Stay Today</p>
                            <Link to='/properties'>
                                <button className='header-button btn btn-light'>Explore Properties
                                    <CottageOutlinedIcon className='icon' color='' size='large'/>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )
}

export default Home